import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";

const MainHeader = () => {
  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  return (
    <>
      <div className="container">
        <div className="row mt-5 mb-5">
          <div className="col-12 text-center">
            <p style={options[textSizeSelected].styleOver}>{langSelected === 0 ? 'Tehnologija betona i upravljanje tehnologijom proizvodnje betona' : 'Concrete technology and the management of concrete manufacturing'}</p>
          </div>
        </div>

      </div>
    </>
  );
};

export default MainHeader;
