import React from 'react';
import { ReactVideo } from "reactjs-media";
import ImagePoster from '../assets/logo_za_video.png'

const  Video = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 align-content-center">
          <ReactVideo
            src={process.env.PUBLIC_URL + '/videos/Pinky_s_video.mp4'}
            poster={ImagePoster}
            primaryColor="red"
            // other props
          />
        </div>
      </div>
    </div>
  );
}

export default Video;