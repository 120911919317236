import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";

const TermsOfUse = () => {

    const options = useFontSizeStore(state => state.defs)
    const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <div className="container">

        <div className="row">
            <div className="col-8">
                <p style={options[textSizeSelected].styleOver}>Uvjeti korištenja</p>
            </div>
            <div className="col-4">

            </div>
        </div>

        <div className="row mt-1">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    PINKY-S  d.o.o.Sv. Pribislavec, V.Nazora 3, 40000 Čakovec, Hrvatska, OIB:45625370137 u obavljanju svoje djelatnosti, prikuplja i obrađuje osobne podatke poštujući sve relevantne zakone i propise.
                    <br/>
                    Mi u PINKY-S  d.o.o. obavezujemo se da ćemo čuvati privatnost i tajnost osobnih podataka svih naših posjetilaca i kupaca, u skladu sa Zakonom o elektroničkoj trgovini (Narodne novine br. 173/2003 , 67/2008 i 36/2009).
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-1">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Molimo vas da ovu Izjavu pročitate pažljivo kako biste saznali kako skupljamo, obrađujemo, štitimo ili na bilo koji drugi način koristimo vaše osobne podatke.
                    <br/>
                    Obvezujemo se primjenjivati maksimalne mjere sigurnosti u svrhu zaštite interesa kupaca i sprječavanja eventualnih zlouporaba informacija. U procesu realizacije kupovine koristit ćemo samo informacije koje su nužne. Svi zaposleni u PINKY-S  d.o.o. i poslovni partneri ugovorno su obvezni poštovati načela zaštite privatnosti i tajnosti podataka. Prikupljamo samo neophodne, osnovne podatke o kupcima/korisnicima i obavještavamo posjetioce i kupce o načinu korištenja tih podataka. Redovno dajemo kupcima mogućnost izbora o upotrebi njihovih podataka, uključujući mogućnost odluke žele li ili ne da se njihovo ime ukloni s lista koje se koriste za marketinške kampanje.
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-1">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Osobni podaci kupaca spremljeni su u informatičkom sustavu tvrtke PINKY-S  d.o.o. i čuvaju se na sigurnom mjestu i dostupni su samo zaposlenima PINKY-S  d.o.o. kojima su ti podaci nužni za pružanje kvalitetne usluge našim kupcima. Upotreba podataka ograničena je na komunikaciju s kupcima u svrhu obavljanja prodajnih transakcija, i obavještavanje korisnika o novostima i pogodnostima koje su im dostupne putem naše web stranice.
                    <br/>
                    PINKY-S  d.o.o. garantira da osobni podaci korisnika Web stranice PINKY-S  d.o.o. neće nikada biti dati na uvid i upotrebu trećoj strani, bez prethodne izričite suglasnosti korisnika. Ovo se ne odnosi na zahtjeve za uvid u podatke od strane ovlaštenih službi izvršne državne vlasti Republike Hrvatske za potrebe kontrole poslovanja.
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-1">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Prikupljamo samo one osobne podatke koje nam dobrovoljno dostavite i samo za svrhe navedene u ovim pravilima zaštite osobnih podataka. Ne zahtijevamo od Vas da nam pošaljete te podatke kako bismo vam omogućili pristup na naše stranice i ne tražimo da otkrijete više podataka no što je doista potrebno za sudjelovanje u nekoj aktivnosti na našim stranicama.
                    <br/>
                    Registracijom na internet stranici http://pinky-s.com, kupnjom kao gost ili pristupanjem samoj stranici kupac daje suglasnost da PINKY-S  d.o.o. obrađuje njegove osobne podatke navedene u registracijskoj formi, podatke o narudžbama, podatke dostupne u procesu plaćanja usluge i druge podatke. Isti podaci koriste se u svrhu sklapanja ugovora, te u svrhu upoznavanja PINKY-S  d.o.o. s kupovnim navikama kupaca, kao i u informativne svrhe, te svrhe promidžbe usluga i proizvoda prodavatelja.
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-1">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    PINKY-S  d.o.o. se obvezuje čuvati privatnost osobnih podataka svih Kupaca, te će s istima postupati u skladu sa Zakonom o zaštiti osobnih podataka, odnosno drugim primjenjivim propisima.
                    <br/>
                    Registracijom potvrđujete točnost i potpunost u obrascu navedenih podataka i dajete izričitu suglasnost da PINKY-S  d.o.o. može, u skladu za odredbama (NN br. 103/2003 i 118/2006) i Zakona o elektroničkoj trgovini(NN br. br. 173/2003 , 67/2008 i 36/2009), obrađivati osobne podatke naznačene u obrascu za potrebe svojih evidencija i pružanja usluga obavještavanja o novim proizvodima i uslugama.
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-2">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Voditelj obrade osobnih podataka
                    <br/>
                    PINKY-S  d.o.o. je voditelj obrade osobnih podataka u skladu sa zakonom i propisima o zaštiti osobnih podataka. PINKY-S  d.o.o. kao voditelj obrade osobnih podataka određuje svrhu i sredstva obrade osobnih podataka i odgovoran je za čuvanje i korištenje osobnih podataka u papirnatom i/ili elektroničkom obliku. Za sva pitanja vezana za obradu osobnih podataka ili ostvarivanje prava na zaštitu osobnih podataka možete nas kontaktirati na e-mail: pinky-s@pinky-s.com.
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-2">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Osobni podaci koje prikupljamo
                    <br/>
                    Osobni podatak jest svaki podatak koji se odnosi na pojedinca čiji je identitet poznat ili se može utvrditi. Pojedinac čiji se identitet može utvrditi jest osoba koja se može identificirati izravno ili neizravno, osobito uz pomoć identifikatora kao što su ime, identifikacijski broj, podaci o lokaciji, mrežni identifikator ili uz pomoć jednog ili više čimbenika svojstvenih za fizički, fiziološki, genetski, mentalni, ekonomski, kulturni ili socijalni identitet tog pojedinca.
                    <br />
                    Vaše osobne podatke prikupljamo samo kada nam svojom voljom date takve informacije. Podatke o Vama prikupljamo kada ispunite web obrazac ili prilikom prijave na newsletter.
                    <br />
                    Trebali biste imati na umu da se neosobne informacije i podaci mogu automatski prikupljati putem naših Internet servera ili korištenjem “kolačića”.
                    <br/>
                    Primjeri informacija o Vašem korištenju stranice uključuju: najposjećenije i najgledanije stranice i poveznice na našoj web stranici, broj ispunjenih obrazaca, vrijeme provedeno na stranici, najpopularnije ključne riječi koji korisnike vode do naše stranice, vaša IP adresa, informacije koje se skupljaju putem kolačića, podaci vašeg uređaja poput postavki hardware-a, sistemske aktivnosti, vrste pretraživača itd.
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-1">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Za potrebe poslovanja PINKY-S  d.o.o. prikuplja sljedeće korisničke podatke:
                    <br/>
                    • Ime i prezime<br/>

                    • Adresu i mjesto stanovanja<br/>

                    • E-mail adresu<br/>

                    • Kontaktni telefonski broj<br/>
                    Za točnost unesenih podataka koje su unijeli korisnici ne odgovaramo.
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-1">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Svrha obrade osobnih podataka
                    <br/>
                    Razlozi zbog kojih obrađujemo osobne podatke radi sklapanja i ispunjenja ugovornih obveza su:<br/>
                    • Otvaranje korisničkih profila na web stranici<br/>
                    • Obrada narudžbi putem online trgovine i isporuka/dostava naručenih proizvoda<br/>
                    • Prijenos komercijalnih komunikacija putem elektroničke pošte, mobilnih telefona ili tradicionalnom poštom odnosno kurirskom dostavom (unutar granica Vaše suglasnosti)<br/>
                    • Pružanje odgovora na upite koje nam postavite ili na Vaše savjete, komentare, prigovore i sl.<br/>
                    • Rješavanje svih sporova ili pritužbi koji se odnose na ili proizlaze iz aktivnosti PINKY-S  d.o.o..<br/>
                    • Promocije naših usluga i iskazivanja namjere sklapanja ugovora<br/>
                    • Prodajnih i marketinških aktivnosti PINKY-S  d.o.o.<br/>
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>


        <div className="row mt-2">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Pravna osnova obrade osobnih podataka
                    <br/>
                    Kupac unosom svojih osobnih podataka te potvrdom (klikom) o prihvaćanju Uvjeta kupnje i ove Izjave o privatnosti sklapa ugovorni odnos koji je temelj za kupnju proizvoda i usluga po izboru kupca na Internet stranici http://pinky-s.com, a koji je sadržan u Uvjetima kupnje te je stoga obrada tih osobnih podataka zakonita jer se poduzimaju radnje na zahtjev Kupca radi realizacije kupnje proizvoda i usluga po narudžbi Kupca.<br/>
                    Primanje marketinških kampanja na e-mail (newsletter)<br/>
                    Ako se odlučite prijaviti na našu newsletter listu, e-mail adresa koju nam pošaljete prosljeđuje se softverskoj platformi koja nam pruža usluge e-mail marketinga. Email adresa koju šaljete neće biti pohranjena u vlastitoj bazi podataka ili na nekom od naših internih računalnih sustava.<br/>
                    Vaša adresa e-pošte ostaje unutar baze podataka navedene platforme dokle god nastavljamo koristiti usluge te platforme za email marketing ili dok ne zatražite odjavu s newsletter liste. To možete učiniti tako što ćete otkazati pretplatu pomoću linkova za otkazivanje pretplate koji se nalaze u svim newsletterima koje vam šaljemo ili tako da zatražite odjavu putem naše kontakt email adrese.<br/>
                    Korisnik koji se prijavljuje za primanje obavijesti o proizvodima i akcijama unosom svojih podataka na Internet stranici http://pinky-s.com te dvostrukom potvrdom ispravnosti mail adrese, daje privolu za obradu svojih osobnih podataka.<br/>
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-2">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Komuniciranje podataka prema trećim stranama
                    <br/>
                    PINKY-S  d.o.o. neće dijeliti osobne podatke Kupca odnosno Korisnika s drugim stranama osim u slučajevima navedenim u sljedećim točkama i u situacijama kada to pozitivni propisi zahtijevaju.<br/>
                    PINKY-S  d.o.o. će kada to zahtijeva realizacija ugovora za kupnju proizvoda ili usluga po narudžbi Kupca podijeliti osobne podatke Kupca s:<br/>
                    • Pružateljima usluga distribucije robe s kojima ima trajni ugovor te u tu svrhu za ispunjavanje narudžbi, isporuku paketa, slanje zemaljske pošte i e-mail pošte. Pružatelj usluga distribucije može od Kupca zatražiti na uvid njegovu osobnu iskaznicu u trenutku isporuke paketa prilikom osobnog preuzimanja robe, a sve u svrhu realizacije usluge isporuke paketa i evidencije o tome tko je preuzeo paket. Ako Kupac odbije dati ove podatke, paket mu neće biti uručen. Pružatelj usluge distribucije može na e-mail adresu Kupca poslati link za praćenje statusa pošiljke.<br/>
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-2">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Obrada osobnih podataka u procesu plaćanja kreditnim i debitnim karticama
                    <br/>
                    PINKY-S  d.o.o. u trenutku plaćanja na Internet stranici http://pinky-s.com, kao uvjet plaćanja proizvoda i usluga kreditnim ili debitnim karticama traži privolu Kupca za aktivaciju procesa plaćanja putem Corvuspay-a, ugovornog partnera PINKY-S  d.o.o. i Izvršitelja obrade osobnih podataka. U tu svrhu osobni podaci Kupca (ime i prezime Kupca, adresa Kupca, podaci s kartice Kupca) su privremeno pohranjeni kod CorvusPay-a, koji te podatke pohranjuje u skladu s PCI DSS certifikacijom, najvišim stupnjem zaštite i čuvanja povjerljivih podataka.<br/>
                    Djelatnici PINKY-S  d.o.o. internet trgovine ni u jednom trenutku nemaju pristup broju Vaše kreditne kartice, dostupan im je samo broj autorizirane transakcije. Podaci o karticama ne pohranjuju se u našem sustavu već se broj kreditne kartice direktno provjerava i autorizira u autorizacijskom centru kartične kuće. Broj kreditne kartice nemojte upisivati nigdje osim u za to predviđeno polje, ne šaljite ga e-mailom niti putem formulara za kontakt.<br/>
                    PINKY-S  d.o.o. ne preuzima odgovornost za eventualne izravne ili neizravne štete koje Kupci mogu pretrpjeti zbog privremene, djelomične ili potpune nedostupnosti CorvusPay™ usluge.<br/>
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-2">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Brisanje osobnih podataka (pravo na zaborav, povlačenje privole)
                    <br/>
                    U slučaju da Kupac ne želi da prodavatelj više na bilo koji način obrađuje podatke istoga, te zahtijeva brisanje podataka o istome, mora to prodavatelju javiti, i to putem e-mail poruke na e-mail adresu pinky-s@pinky-s.com
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-2">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Pravo na prigovor
                    <br/>
                    Ako unatoč svim poduzetim mjerama za zaštitu osobnih podataka smatrate da imate osnove za prigovor, javite se Voditelju obrade osobnih podataka na mail adresu pinky-s@pinky-s.com<br/>
                    Prigovor na obradu Vaših osobnih podataka možete podnijeti i nadzornom tijelu Agenciji za zaštitu osobnih podataka (AZOP).
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-2">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Pristup i ispravak osobnim podacima
                    <br/>
                    Kupac i Korisnik u svakom trenutku ima mogućnost pristupa svojim osobnim podacima po registraciji na stranici te pristupom na “Pojedinosti o kupcu" gdje Kupac može revidirati svoje osobne podatke koje je podijelio s PINKY-S  d.o.o. Kupac i Korisnik može zatražiti i dobiti od PINKY-S  d.o.o. cjelovitu informaciju o osobnim podacima koji su pohranjeni, kao i ispravak istih slanjem e-mail poruke na mail adresu Voditelja obrade osobnih podataka: pinky-s@pinky-s.com
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-2">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Razdoblje pohranjivanja osobnih podataka
                    <br/>
                    Vaše podatke ćemo čuvati onoliko dugo koliko je potrebno za ostvarenje svrhe radi koje ih obrađujemo. Kriterij na temelju kojega određujemo razdoblje čuvanja osobnih podataka je upravo svrha prikupljanja i vremenski rok u kojem želite da Vam pružamo naše usluge.
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-2">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Sigurnosne mjere zaštite osobnih podataka – enkripcija
                    <br/>
                    Vaši podaci i sve stranice http://pinky-s.com internet trgovine zaštićene su korištenjem Secure Socket Layer (SSL) protokola koji koristi sha256 sigurnosni algoritam. SSL enkripcija šifrira podatke radi sprječavanja neovlaštenog pristupa prilikom njihovog prijenosa.
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-2">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Kršenje privatnosti podataka
                    <br/>
                    O svakom kršenju privatnosti podataka iz naše baze ili baze bilo kojeg od naših partnera obavijestit ćemo sve relevantne osobe i nadležna tijela u roku od 72 sata od prekršaja, ako je očigledno da su ukradeni podaci spremljeni na način da se može otkriti identitet vlasnika podataka.
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-2">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Promjene izjave o privatnosti
                    <br/>
                    Ova izjava o privatnosti može se s vremena na vrijeme mijenjati u skladu sa zakonodavstvom ili razvojem industrije. Nećemo eksplicitno obavijestiti naše klijente ili korisnike web stranica o tim promjenama. Umjesto toga, preporučujemo da povremeno provjeravate ovu stranicu za sve izmjene izjave.
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>

        <div className="row mt-2">
            <div className="col-lg-8 col-sm-12">
                <p style={options[textSizeSelected].styleNormal}>
                    Vaša privola
                    <br/>
                    Korištenjem ove web stranice dajete privolu na ovu politiku privatnosti.
                </p>
            </div>
            <div className="col-lg-4 col-sm-12">
            </div>
        </div>


    </div>
  );
};

export default TermsOfUse;
