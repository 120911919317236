import React, {useState} from 'react';
import Image from '../assets/slide21.JPG'
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Aditives2 from "./Aditives2";
import Aditives3 from "./Aditives3";
import Aditives4 from "./Aditives4";

const Aditives = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const [option, SetOption] = useState(1)
  const langSelected = useFontSizeStore((state) => state.lang)
  return (
    <>
      <img src={Image} className="img-fluid" style={{width:'100%', height:'600px', paddingBottom:'20px'}} alt="Aditives" />
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-12">

          </div>
          <div className="col-lg-8 col-sm-12">
            <button type="button" className="btn btn-link mt-0 mb-0 pt-0 pb-0" onClick={() => SetOption(1)}>
              <p style={options[textSizeSelected].styleOver}>{langSelected === 0 ? 'KEMIJSKI DODACI ZA BETON' : 'CHEMICAL ADMIXTURES FOR CONCRETE'}</p>
            </button>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-lg-4 col-sm-12">
            <ul className="list-group list-group-flush">
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(2)}><p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'DODACI ZA ZEMLJOVLAŽNI BETON' : 'ADMIXTURES FOR SEMI-DRY CONCRETE'}</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(3)}><p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'DODACI ZA TRANSPORTNE BETONE I BETONE ZA PREDGOTOVLJENE BETONSKE ELEMENTE' : 'ADMIXTURES FOR TRANSPORTATION OF CONCRETE AND CONCRETE FOR PRECAST CONCRETE ELEMENTS'}</p></button>
              </li>
            </ul>

          </div>
          <div className="col-lg-8 col-sm-12">
            {option === 1 && <Aditives2 />}
            {option === 2 && <Aditives3 />}
            {option === 3 && <Aditives4 />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Aditives;
