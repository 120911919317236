import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdBadge} from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";

const Contacts = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  const Mailto = ({ email, subject = '', body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  return (
    <div className="container">
      <div className="row mt-0 mb-0">
        <div className="col-lg-1 col-2 mt-2 text-center">
          <FontAwesomeIcon icon={faIdBadge} className="fa-3x"/>
        </div>
        <div className="col-lg-4 col-8">
          <p style={options[textSizeSelected].styleNormalBold} className="mt-0 mb-0">{langSelected === 0 ? 'Kontakt' : 'Contact'}</p>
          <p style={options[textSizeSelected].styleNormalLight} className="mt-0 mb-0">
            <Mailto email="pinky-s@pinky-s.com" subject="Info" body="">
              email: pinky-s@pinky-s.com
            </Mailto>,
          </p>
          <p style={options[textSizeSelected].styleNormalLight}>
            +385 40 360 700
          </p>
        </div>
        <div className="col-1">

        </div>
        <div className="col-lg-1 col-2 mt-2 text-center">
          <FontAwesomeIcon icon={faMapMarkerAlt} className="fa-3x"/>
        </div>
        <div className="col-lg-4 col-8">
          <p style={options[textSizeSelected].styleNormalBold} className="mt-0 mb-0">{langSelected === 0 ? 'Naša lokacija' : 'Our location'}</p>
          <p style={options[textSizeSelected].styleNormalLight} className="mt-0 mb-0">
            Pribislavec, V.Nazora 3
          </p>
          <p style={options[textSizeSelected].styleNormalLight}>
            {langSelected === 0 ? 'Hrvatska/Croatia' : 'Croatia/Croatia'}  40000 Čakovec
          </p>
        </div>
      </div>

    </div>
  );
};

export default Contacts;
