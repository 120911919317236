import React, {useState} from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image from "../assets/slide11.JPG";
import PigmentsIronOxide from "./PigmentsIronOxide";
import PigmentsFluid from "./PigmentsFluid";
import PigmentsGreen from "./PigmentsGreen";
import PigmentsBlue from "./PigmentsBlue";
import Pigments2 from "./Pigments2";
import PigmentsCobalt from "./PigmentsCobalt";
import PigmentsSpecials from "./PigmetsSpecials";
import PigmentsRoofTiles from "./PigmetnsRoofTiles";

const Pigments = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)
  const [option, SetOption] = useState(1)

  return (
    <>
      <img src={Image} className="img-fluid" style={{width:'100%', height:'600px', paddingBottom:'20px'}} alt="Pigments" />

      <div className="container">

        <div className="row">
          <div className="col-lg-3 col-sm-12">

          </div>
          <div className="col-lg-9 col-sm-12">
            <p style={options[textSizeSelected].styleOver}>Pigmenti</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-lg-3 col-sm-12">
            <ul className="list-group list-group-flush">
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(1)}><p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'PIGMENTI' : 'PIGMENTS'}</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(2)}><p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'ŽELJEZNI OKSIDI' : 'IRON OXIDES'}</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(3)}><p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'TEKUĆI PIGMENTI ZA BETON' : 'LIQUID PIGMENTS FOR CONCRETE'}</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(4)}><p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'ZELENI KROM III OKSID' : 'GREEN CHROMIUM III OXIDE'}</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(5)}><p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'PLAVI ULTRAMARINE' : 'BLUE ULTRAMARINE'}</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(6)}><p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'KOBALT PLAVI' : 'COBALT BLUE'}</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(7)}><p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'PROIZVODNJA BOJA SPECIJALNE NAMJENE' : 'PRODUCTION OF SPECIAL PURPOSE PAINTS'}</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(8)}><p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'BOJA ZA BETONSKI CRIJEP' : 'PAINT FOR CONCRETE  ROOF TILES'}</p></button>
              </li>
            </ul>
          </div>
          <div className="col-lg-9 col-sm-12">
            {option === 1 && <Pigments2 />}
            {option === 2 && <PigmentsIronOxide />}
            {option === 3 && <PigmentsFluid />}
            {option === 4 && <PigmentsGreen />}
            {option === 5 && <PigmentsBlue />}
            {option === 6 && <PigmentsCobalt />}
            {option === 7 && <PigmentsSpecials />}
            {option === 8 && <PigmentsRoofTiles />}
          </div>
        </div>

      </div>
    </>
  );
};

export default Pigments;
