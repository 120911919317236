import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/doziranje_kemijskih_dodataka.png";

const Automation7 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)
  return (
    <>

      <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="Auto" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'DOZIRANJE KEMIJSKIH DODATAKA' : 'DOSING OF CHEMICAL ADDITIVES'}</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Kada betonara ima starije tehničko i/ili software-sko rješenje na koje je nemoguće instalirati novu opremu ili program, ili su toliko stari da nije moguće automatizirati cijeli proces, Pinky-s Vam nudi nezavisni sustav doziranja koji se povezuje na bilo koju postojeću betonaru.' : 'If a concrete plant has older technical and/or software solutions, preventing the installation of new equipment or programmes, or they are too old to automate the entire process altogether, Pinky-s can offer you an independent dosing system that connects to any existing concrete plant.'} </p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Automation7;
