import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
const Careers = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  return (
    <div className="container">
      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleOver}>{langSelected === 0 ? 'Karijere' : 'Jobs & careers'}</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'AUTOMATIČAR' : 'AUTOMATION ENGINEER'}</p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Tražimo djelatnika u odjelu Automatizacije' : 'We are looking for an employee in the Automation department'}
            <br/>
              {langSelected === 0 ? '-znanje Siemens automatizacije' : '-Knowledge of Siemens automation required'}
            <br/>
              {langSelected === 0 ? '-znanje Visual Studio VB.NET i(ili) C#' : '-Knowledge of Visual Studio VB.NET and/or C# required'}
            <br/>
              {langSelected === 0 ? '-znanje MS SQL server' : '- Knowledge of MS SQL server required'}
            <br/>
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'GRAĐEVISNKI TEHNIČAR/LABORANT' : 'CONSTRUCTION AND LABORATORY TECHNICIAN '} </p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Tražimo djelatnika u odjelu Pigmenti/Aditivi' : 'We are looking for an employee in the Pigments/Additives department'}
            <br/>
              {langSelected === 0 ? '- poznavanje tehnologije betona' : '- Knowledge of concrete technology required'}
            <br/>
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>


      <div className="row mt-4">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'STRUČNE PRAKSE' : 'INTERNSHIPS'} </p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? '- za učenike i studente elektrotehničkih /mehatroničkih usmjerenja.' : '- Available for pupils and students who attend electro technical/mechatronics training'}
            <br/>
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>

      <div className="row mt-5">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'Volite izazove? Zainteresirani ste za rad u dinamičnom okruženju?' : 'Do you like challenges? Are you interested in working in a dynamic environment?'} </p>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'Kontaktirajte nas' : 'Please feel free to contact us!'} </p>
        </div>
      </div>

    </div>
  );
};

export default Careers;
