import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import NewsDetail from "./NewsDetail";

const News = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  const head1 = {}
  head1.headline = (langSelected === 0 ? 'Automatika' : 'Automation')
  head1.text = (langSelected === 0 ? 'Za našeg dugogodišnjeg partnera u Hrvatskoj započeli smo novi projekt: Kompletna rekonstrukcija linije MASA.' : 'For our long-term partner in Croatia, we started a new project: The complete reconstruction of the MASA line.')
  head1.image = null
  head1.date = '25.03.21. '

  const head2 = {}
  head2.headline = (langSelected === 0 ? 'Automatika' : 'Automation')
  head2.text = (langSelected === 0 ? ' Za partnera u Hrvatskoj uspješno smo završili projekt: Kompletna rekonstrukcija linije OMAG 1. ' : 'For our partner in Croatia, we successfully completed a project: The complete reconstruction of the OMAG 1 line.')
  head2.image = null
  head2.date = '19.03.21.'

  const head3 = {}
  head3.headline = (langSelected === 0 ? 'Automatika' : 'Automation')
  head3.text = (langSelected === 0 ? 'S partnerom iz Slovenije potpisan ugovor za novi projekt: Rekonstrukcija upravljanja linijom Zenit.' : 'We signed a contract with a partner from Slovenia for a new project: The reconstruction of the management of the Zenit line.')
  head3.image = null
  head3.date = '19.03.21.'

  const head4 = {}
  head4.headline = (langSelected === 0 ? 'Zapošljavamo' : 'We are hiring')
  head4.text = (langSelected === 0 ? 'Zapošljavamo - raspisan je natječaj za radno mjesto:  Inženjer istraživanja i razvoja kemijskih proizvoda.' : 'We are hiring. A vacancy has been announced for the following position: Research Engineer in the field of development of chemical products.')
  head4.image = null
  head4.date = '03.03.21.'

  const head5 = {}
  head5.headline = (langSelected === 0 ? 'Oprema' : 'Equipment')
  head5.text = (langSelected === 0 ? 'Za kupca u Hrvatskoj uspješno smo završili montažu betonare ELKOMIX 120 QUICK MASTER, proizvođača ELKON.' : 'We have successfully completed an installation of the ELKOMIX 120 QUICK MASTER concrete mixer, manufactured by ELKON, for a customer in Croatia.')
  head5.image = null
  head5.date = '20.01.21.'

  const head6 = {}
  head6.headline = (langSelected === 0 ? 'Automatika, oprema' : 'Automation Equipment')
  head6.text = (langSelected === 0 ? 'Za našeg dugogodišnjeg partnera u BIH, naš tim iz odjela automatizacije završio je montažu opreme vlastite proizvodnje: UREĐAJ ZA INLINE IMPREGNACIJU.' : 'For our long-term partner in Bosnia and Herzegovina, our automation department team finished assembling the equipment manufactured by us: DEVICE FOR INLINE IMPREGNATION.')
  head6.image = null
  head6.date = '15.01.21.'

  const head7 = {}
  head7.headline = (langSelected === 0 ? 'Oprema' : 'Equipment')
  head7.text = (langSelected === 0 ? 'U proizvodnji našeg dugogodišnjeg slovenskog kupca montirali smo opremu vlastite proizvodnje - GRANUMIN MINI, za doziranje pigmenta u granulama.' : 'Our own equipment - GRANUMIN MINI - for dosing of pigment granules, has been installed in the production line of our long-time Slovenian customer.')
  head7.image = null
  head7.date = '09.12.20. '

  return (
    <div className="container">
      <div className="row mt-4">
        <div className="col-8">
          <p style={options[textSizeSelected].styleOver}>{langSelected === 0 ? 'Novosti' : 'News'}</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <NewsDetail head={head1}/>
      <NewsDetail head={head2}/>
      <NewsDetail head={head3}/>
      <NewsDetail head={head4}/>
      <NewsDetail head={head5}/>
      <NewsDetail head={head6}/>
      <NewsDetail head={head7}/>
    </div>
  );
}

export default News;
