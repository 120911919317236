import React, {useState} from 'react';
import Image from "../assets/stupnik_sve.png";
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Automation2 from "./Automation2";
import Automation3 from "./Automation3";
import Automation4 from "./Automation4";
import Automation5 from "./Automation5";
import Automation6 from "./Automation6";
import Automation7 from "./Automation7";
import Automation8 from "./Automation8";
import Automation10 from "./Automation10";
import Automation11 from "./Automation11";
import Automation12 from "./Automation12";
import {autoHR, autoEN} from "./textHr";

const Automation = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const [option, SetOption] = useState(1)
  const langSelected = useFontSizeStore((state) => state.lang)
  const lang = (langSelected === 0 ? autoHR : autoEN)

  return (
    <>
      <img src={Image} className="img-fluid" style={{width:'100%', height:'600px', paddingBottom:'20px'}} alt="Automation" />
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-sm-12">

          </div>
          <div className="col-lg-8 col-sm-12">
            <button type="button" className="btn btn-link mt-0 mb-0 pt-0 pb-0" onClick={() => SetOption(1)}>
              <p style={options[textSizeSelected].styleOver}>{langSelected === 0 ? 'Automatika' : 'Automation'}</p>
            </button>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-lg-4 col-sm-12 text-left">
            <ul className="list-group list-group-flush">
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(2)}><p style={options[textSizeSelected].styleNormalBold}>{lang.menu1}</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(3)}><p style={options[textSizeSelected].styleNormalBold}>{lang.menu2}</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(4)}><p style={options[textSizeSelected].styleNormalBold}>{lang.menu3}</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(5)}><p style={options[textSizeSelected].styleNormalBold}>{lang.menu4}</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(6)}><p style={options[textSizeSelected].styleNormalBold}>{lang.menu5}</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(7)}><p style={options[textSizeSelected].styleNormalBold}>{lang.menu6}</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(10)}><p style={options[textSizeSelected].styleNormalBold}>{lang.menu9}</p></button>
              </li>
              <li className="list-group-item m-0 p-0">
                <button type="button" className="btn btn-link text-left m-0 p-0" onClick={() => SetOption(11)}><p style={options[textSizeSelected].styleNormalBold}>{lang.menu10}</p></button>
              </li>
            </ul>

          </div>
          <div className="col-lg-8 col-sm-12">
            {option === 1 && <Automation2 />}
            {option === 2 && <Automation3 />}
            {option === 3 && <Automation4 />}
            {option === 4 && <Automation5 />}
            {option === 5 && <Automation6 />}
            {option === 6 && <Automation7 />}
            {option === 7 && <Automation8 />}
            {option === 9 && <Automation10 />}
            {option === 10 && <Automation11 />}
            {option === 11 && <Automation12 />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Automation;
