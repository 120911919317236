import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/punilica_program.jpeg";

const Automation10 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  return (
    <>

      <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="Auto" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'PUNILICA BOJE' : 'PUNILICA BOJE'}</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Punilica boje je samostalni poluautomatski ili automatski uređaj. Punilica ima ekran osjetljiv na dodir. Na ekranu se unose osnovni parametri: količina, preticanje, postotak grubog doziranja dok se za ostale postavke može koristiti tipkovnica.' : 'Punilica boje je samostalni poluautomatski ili automatski uređaj. Punilica ima ekran osjetljiv na dodir. Na ekranu se unose osnovni parametri: količina, preticanje, postotak grubog doziranja dok se za ostale postavke može koristiti tipkovnica.'}
            <br/>
              {langSelected === 0 ? 'Software i oprema su u cijelosti projektirani i izrađeni u PINKY-S d.o.o.' : 'Software i oprema su u cijelosti projektirani i izrađeni u PINKY-S d.o.o.'}
            </p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Automation10;
