import React from 'react';
import AboutUsImg from '../assets/onama.jpeg'
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Video from "./Video";

const AboutUs = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  return (
    <div className="container">

      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleOver}>{langSelected === 0 ? 'O nama' : 'About us'}</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <Video />

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Tvrtka Pinky-s d.o.o. osnovana je 1993. godine. Posluje u industriji betona, te industriji boja i lakova, za koje nudi široku paletu proizvoda i usluga. U cilju maksimalnog zadovoljenja potreba naših kupaca, 2003. godine Pinky-s d.o.o. u poslovanje uvodi međunarodni standard kvalitete ISO 9001:2015. Razvojem vlastite proizvodnje Pinky-s d.o.o. počinje uviđati sve značajnije potrebe za očuvanjem okoliša, pa 2004. godine u poslovanje uvodi i međunarodni standard ISO 14001:2015, u cilju odgovornog poslovanja prema okolišu te kontroliranom zbrinjavanju otpada.' : 'The company Pinky-s d.o.o. was founded in 1993. It operates in the concrete industry as well as the paint and coatings industry, for which it offers a wide range of products and services. In order to maximize the needs of our customers, in 2003, Pinky-s d.o.o. introduced the international quality standard ISO 9001:2015 into its operations. By developing its own production processes, Pinky-s d.o.o. began to see increased needs for environmental protection. In 2004, we introduced the international standard ISO 14001:2015 into our operations, with the aim of environmentally responsible business operations and controlled waste disposal.'}
            <br/>
              {langSelected === 0 ? 'Kontinuirano ulaganje u razvoj, edukaciju naših zaposlenika, kao i u vlastiti laboratorij za kontrolu kvalitete ulaznih sirovina i vlastitih proizvoda, jamstvo su kvalitete isporučenih proizvoda i usluga.' : 'Continuous investment in the development and education of our employees, as well as our own laboratory for quality control of raw materials and our products, is a guarantee of the quality of products and services delivered.'}
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
          <img src={AboutUsImg} className="img-fluid"  alt="About Us" />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>

          </p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Obratite nam se s povjerenjem!' : 'Contact us with confidence!'}
          </p>
        </div>
      </div>

    </div>
  );
};

export default AboutUs;
