import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/proizvodnja_boja.png";
import Image2 from "../assets/rsz_eko_kuca.jpeg";
import Image3 from "../assets/rsz_djecji_vrtic.jpeg";

const PigmentsSpecials = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'PROIZVODNJA BOJA SPECIJALNE NAMJENE' : 'PRODUCTION OF SPECIAL PURPOSE PAINTS'}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 col-sm-12">
          <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="Pigmenti1" />
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Na temelju dugogodišnjeg iskustva u radu s pigmentima, PINKY-S razvija vlastitu proizvodnju boja specijalne namjene. Posljednjih je godina u tom pogledu postignut velik napredak tako da PINKY-S danas snabdijeva jedno od najvećih poduzeća za proizvodnju ukrasne keramike u Europi.' : 'As a result of many years of experience working with pigments, PINKY-S has developed its own production of special purpose paints. In recent years, great progress has been made in this regard. Today, PINKY-S supplies one of the largest European companies for the production of decorative ceramics.'}
            <br/>
              {langSelected === 0 ? 'Kad je na tržištu primijećena potreba za kvalitetnim premazom za betonski crijep, PINKY-S je krenuo i u tom smjeru. Osim kvalitete premaza, ovdje je izuzetno važna i kakvoća aplikacije. Svojim iskustvom i znanjem, Pinky-s je u mogućnosti pomoći i najzahtjevnijem kupcu.' : 'PINKY-S also responded when a need for quality coating for concrete roof tiles was noticed on the market. In addition to the quality of coating, the quality of application is extremely important here. Relying on its experience and knowledge, PINKY-S is able to answer even the most challenging customer request.'}
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 col-sm-12">
          <img src={Image2} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="PigmentSpecials2" />
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="col-sm-12 m-0 p-0">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'BSB boja za beton.' : 'Pic 1. BSB paint for concrete'}
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 col-sm-12">
          <img src={Image3} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="PigmentSpecials3" />
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="col-sm-12 m-0 p-0">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'EFFLOTEC PY10 premaz.' : 'Pic 2. EFFLOTEC PY10 coating.'}
          </p>
        </div>
      </div>


    </div>
  );
};

export default PigmentsSpecials;
