import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";

const Pigments2 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  return (
    <>

      <div className="container">


        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'PINKY-S d.o.o je tvrtka fokusirana na koncept „boja je ključ“, što znači da je naš cilj hvatanje u koštac s novim izazovima u sveprisutnijoj bojanoj građevinskoj, odnosno betonskoj industriji. Budući da naša tvrtka nudi veliki asortiman proizvoda, možemo zadovoljiti sve potrebe za pigmentima u građevinarstvu, u industriji boja i premaza, industriji papira, a isto tako potrebu kod tiskarskih boja, tekstila, itd. Našim kupcima ne nudimo samo gotove proizvode, već i potrebno znanje i stručnu pomoć pri samoj aplikaciji. Efektivno korištenje pigmenata može uvelike utjecati na profit i sam uspjeh Vaše tvrtke.' : 'PINKY-S d.o.o. is focused on the concept "colour is the key", and our goal is to tackle new challenges in the construction and concrete industry. Since our company has a large range of products on offer, we can fulfil all related requirements for pigments in the construction, the paint and coating industry as well as the paper, textile and printing inks industries… to mention just a few. We offer our customers the finished products, as well as necessary knowledge and professional help with the application itself. The effective use of pigments can greatly impact the profit and success of your company.'}
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>

        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Naši pigmenti imaju vrlo širok raspon primjene i mogu se koristiti za proizvodnju praktički neograničene palete bogatih, izdržljivih boja, te s lakoćom  ostvaruju željeno nijansiranje. PINKY-S ima dugogodišnje iskustvo stečeno iz brojnih područja bojane industrije i upravo to iskustvo nama pomaže da Vama unaprijedimo i poboljšamo proizvodni asortiman. U svakom području, PINKY-S ima tim stručnjaka, uvijek spremnih za savjet i pomoć.' : 'Our pigments have a very wide range of applications and can be used to produce a virtually unlimited range of rich, durable colour and achieve the desired tinting with ease. PINKY-S has many years of experience gained from numerous fields of the paint industry, and it is this experience that helps us to advance and improve our product range. In every area, PINKY-S has a team of experts, always ready to offer advice and help'}
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>

        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Što god proizvodite, naš tim je spreman surađivati s Vama kako biste stvorili boju i efekt koji najbolje odgovara vašim zahtjevima. Nešto takvo, može se postići samo kroz detaljnu raspravu, te identifikaciju samih sirovina koje utječu na boje. Osim toga, predstavljanje naših pigmenata uvijek je dostupno u našem laboratoriju, naravno uz  usporedbu s konkurencijom. Kombiniranjem Vaših i naših znanja, stručnosti i sposobnosti, uz malo mašte, sada možete postići rezultate koji će stvarno zadovoljiti Vaša očekivanja.' : 'Whatever you manufacture, our team is ready to work with you to create the colour and effect that best suits your requirements. This can only be achieved through a detailed discussion and identification of the raw materials. Furthermore, we display our pigments in our laboratory and they can always be compared with the pigments of our competitors. By combining your knowledge with our expertise and abilities, and a little imagination, you can now achieve results that will really meet your expectations.'}
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>

        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Kako se pri radu s pigmentima pojavljuju brojni problemi: određivanje potrebne količine pigmenta, apliciranje premaza, miješanje pigmenta u beton, priprema tekućeg pigmenta, trošenje dijelova strojeva i sl., PINKY-S je počeo proizvoditi specifičnu opremu za rad s pigmentima:' : 'As problems may arise while working with pigments, e.g., determining the required amount of pigment, applying the coating, mixing the pigment into concrete, preparing the liquid pigment, wear of machine parts, etc.,  PINKY-S has started manufacturing specific equipment for these issues:'}
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>

        <div className="row mt-1">
          <div className="col-9 col-sm-12">
            <ul className="list-group list-group-flush">
              <li className="list-group-item">
                <p style={options[textSizeSelected].styleNormal}>
                  {langSelected === 0 ? 'Strojeve za pripremu i doziranje tekućih pigmenata' : 'Machines for the preparation and dosing of liquid pigments'}
                </p>
              </li>
              <li className="list-group-item">
                <p style={options[textSizeSelected].styleNormal}>
                  {langSelected === 0 ? 'Aparate za nanošenje premaza' : 'Devices for application of coatings'}
                </p>
              </li>
              <li className="list-group-item">
                <p style={options[textSizeSelected].styleNormal}>
                  {langSelected === 0 ? 'Rezervne dijelove opreme za proizvodnju betonskog crijepa i drugih betonskih proizvoda' : 'Spare parts needed for the equipment relating to the production of concrete tiles and other concrete products'}
                </p>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </>
  );
};

export default Pigments2;
