import Image1 from '../assets/Binis.jpg'
import Image2 from '../assets/Zagorje_tehnobeton.jpg'
import Image3 from '../assets/Mi-blok.jpg'
import Image4 from '../assets/Bossin.jpg'
import Image5 from '../assets/brod.jpg'
import Image6 from '../assets/kazaliste.jpg'
import {useFontSizeStore} from "../stores/useFontSizeStore";

const LastProjects = () => {
  const langSelected = useFontSizeStore((state) => state.lang)

  return (
    <div className="container mt-3">
      <h2>{langSelected === 0 ? 'Istaknuti projekti' : 'FEATURED PROJECTS'}</h2>

      <div className="row row-cols-1 row-cols-md-3 g-4">
        <div className="col">
          <div className="card h-100">
            <img src={Image1} className="card-img-top" alt="BINIS doo" />
              <div className="card-body">
                <h5 className="card-title">{langSelected === 0 ? 'BINIS d.o.o. - TORANJSKA BETONARA' : 'BINIS d.o.o. – TOWER CONCRETE PLANT'}</h5>
                <p className="card-text">{langSelected === 0 ? 'Upravljanje betonarom i software za praćenje proizvodnje betona' : 'Concrete plant management and software for monitoring concrete production'}</p>
              </div>
              <div className="card-footer">
                <small className="text-muted">.</small>
              </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100">
            <img src={Image2} className="card-img-top" alt="ZAGORJE TEHNOBETON dd"/>
              <div className="card-body">
                <h5 className="card-title">{langSelected === 0 ? 'ZAGORJE TEHNOBETON d.d. – TORANJSKA BETONARA' : 'ZAGORJE TEHNOBETON d.d. - TOWER CONCRETE PLANT'}</h5>
                <p className="card-text">{langSelected === 0 ? 'Upravljanje betonarom i software za praćenje proizvodnje betona.' : 'Concrete plant management and software for monitoring concrete production'}</p>
              </div>
              <div className="card-footer">
                <small className="text-muted">.</small>
              </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100">
            <img src={Image3} className="card-img-top" alt="MI-BLOK" />
              <div className="card-body">
                <h5 className="card-title">{langSelected === 0 ? 'MI-BLOK – LINIJA ZA PROIZVODNJU BETONSKIH ELEMENATA' : 'MI-BLOK – LINE FOR THE PRODUCTION OF CONCRETE ELEMENTS'}</h5>
                <p className="card-text">{langSelected === 0 ? 'Kompletna rekonstrukcija automatskog upravljanja linijom' : 'Complete reconstruction of the fully automated line'}</p>
              </div>
              <div className="card-footer">
                <small className="text-muted">.</small>
              </div>
          </div>
        </div>
      </div>
      <div className="row row-cols-1 row-cols-md-3 g-4 mt-3">
        <div className="col">
          <div className="card h-100">
            <img src={Image4} className="card-img-top" alt="REGALNO SKLADIŠTE" />
            <div className="card-body">
              <h5 className="card-title">{langSelected === 0 ? 'BOSSIN d.o.o. – REGALNO SKLADIŠTE' : 'BOSSIN d.o.o. – RACK WAREHOUSE'}</h5>
              <p className="card-text">{langSelected === 0 ? 'Automatizacija procesa sušare i upravljanja regalnim skladištem' : 'Automation of the drying process and rack warehouse management'}</p>
            </div>
            <div className="card-footer">
              <small className="text-muted">.</small>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100">
            <img src={Image5} className="card-img-top" alt="BROD BETONARA"/>
            <div className="card-body">
              <h5 className="card-title">{langSelected === 0 ? 'STAV d.o.o./Brodosplit d.d.-BROD BETONARA' : 'STAV d.o.o. / BRODOSPLIT d.d. – MARINE (FLOATING) CONCRETE PLANT'}</h5>
              <p className="card-text">{langSelected === 0 ? 'Upravljanje betonarom i software za praćenje proizvodnje betona' : 'Concrete plant management and software for monitoring concrete production'}</p>
            </div>
            <div className="card-footer">
              <small className="text-muted">.</small>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="card h-100">
            <img src={Image6} className="card-img-top" alt="ZAGREBAČKO KAZALIŠTE LUTAKA" />
            <div className="card-body">
              <h5 className="card-title">{langSelected === 0 ? 'ZAGREBAČKO KAZALIŠTE LUTAKA – AUTOMATSKO UPRAVLJANJE SCENOM' : 'ZAGREB PUPPET THEATRE – AUTOMATIC STAGE CONTROL'}</h5>
              <p className="card-text">{langSelected === 0 ? 'Jedini projekt izvan naše djelatnosti, no od značajnog doprinosa široj društvenoj zajednici' : 'The only project outside of our domain, but one with a significant contribution to the wider community.'}</p>
            </div>
            <div className="card-footer">
              <small className="text-muted">.</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LastProjects;
