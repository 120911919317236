import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image3 from "../assets/rsz_wetophobe.png";
import Image2 from '../assets/plastifikator.jpeg'
import Dots from "../elements/Dots";
import Image1 from "../assets/Aditivi_uvodna.png";

const Aditives3 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  return (
    <>
      <div className="container">

        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'DODACI ZA ZEMLJO-VLAŽNE BETONE' : 'ADMIXTURES FOR SEMI-DRY CONCRETE'}</p>
          </div>
        </div>

        <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="Auto" />

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Zbog mnogih specifičnosti zemljo-vlažnih betona koristimo kemijske dodatke kako bismo poboljšali kvalitetu gotovih proizvoda izrađenih od takvih betona.' : 'Due to the many specifics of semi-dry concrete, we use chemical admixtures to improve the quality of finished products made of such concrete.'}<br/><br/>
              {langSelected === 0 ? 'Proizvodnja proizvoda od zemljo-vlažnih betona relativno je nova  tehnologija koja brzo napreduje.' : 'The production of semi-dry concrete products is a relatively new technology that is advancing rapidly. '}<br/><br/>
              {langSelected === 0 ? 'Nove vrste automatiziranih strojeva za izradu proizvoda od zemljo-vlažnih betona iziskuju posebnu pažnju kod projektiranja receptura za takve betone. Danas je nezamislivo izrađivati proizvode bez specijalnih dodataka, jer se od tih proizvoda osim funkcionalnosti očekuje lijep izgled, otpornost na ekstremne vremenske utjecaje i velika opterećenja.' : 'New types of automated machines for making semi-dry concrete products require special attention when designing recipes for such concretes. Today, it is unthinkable to make products without special accessories, because in addition to their functionality, these products are expected to have a beautiful appearance, resistance to extreme weather conditions and heavy loads.'}<br/><br/>
              {langSelected === 0 ? 'Kapaciteti strojeva također ovise o kemijskim dodacima , jer nekim svojim svojstvima omogućuju skraćenje proizvodnog procesa i smanjeno trošenje dijelova stroja, pa time bitno smanjuju troškove proizvodnje.' : 'The capacities of the machines also depend on the chemical admixtures, because some of their properties enable the shortening of the production process and reduce the wear and tear of the machine parts, thus significantly reducing the production costs. '}<br/><br/>
              {langSelected === 0 ? 'Takvi dodaci imaju utjecaj i na kvalitetu okoliša jer direktno utječu na smanjenje buke i utroška energije.' : 'Such admixtures also have a positive impact on the environment because they directly reduce the noise and energy consumption.'}<br/>
            </p>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'PLASTIFIKATORI' : 'PLASTICIZERS'}</p>
          </div>
        </div>
        <img src={Image2} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}}  alt="Plastifikator3" />

        <div className="row mt-2">
          <div className="col-12">
            <div className="table-responsive-sm">
              <table className="table table-bordered">
                <thead>
                <tr>
                  <th scope="col" className="text-left"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'PLASTIFIKATORI' : 'PLASTICIZERS'}</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'BETONSKE CIJEVI' : 'CONCRETE PIPES'}</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'OPLOČNICI' : 'PAVERS'}<br/>{langSelected === 0 ? 'NOSIVI SLOJ' : 'BASE LAYER'}</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'OPLOČNICI I RUBNJACI' : 'PAVERS AND KERBS'} <br/>{langSelected === 0 ? 'ZAVRŠNI SLOJ' : 'TOP LAYER'}</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'BLOKOVI' : 'BLOCKS'}</p></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>WET MIX 01</p></th>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="1"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>RHEOGLIDE F45A</p></th>
                  <td className="text-center"><Dots numberOf="5"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>WETGLIDE 60</p></th>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>WETGLIDE 01 A</p></th>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="3"/></td>
                  <td className="text-center"><Dots numberOf="3"/></td>
                  <td className="text-center"><Dots numberOf="1"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>WETOPHOBE H80</p></th>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>EFFLO-WET 3100</p></th>
                  <td className="text-center"></td>
                  <td className="text-center"><Dots numberOf="3"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div className="row mt-2">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'DODACI ZA HIDROFOBIRANJE I SMANJENJE EFLORESCENCIJE' : 'ADMIXTURES FOR HYDROPHOBIZATION AND REDUCTION OF EFLORESCENCE'}</p>
          </div>
        </div>

        <img src={Image3} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}}  alt="Plastifikator3" />

        <div className="row mt-2">
          <div className="col-12">
            <div className="table-responsive-sm">
              <table className="table table-bordered">
                <thead>
                <tr>
                  <th scope="col" className="text-left"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'HIDROFOBI' : 'HYDROPHOBICS'}</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'BETONSKE CIJEVI' : 'CONCRETE PIPES'}</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'OPLOČNICI' : 'PAVERS'}<br/>{langSelected === 0 ? 'NOSIVI SLOJ' : 'BASE LAYER'}</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'OPLOČNICI I RUBNJACI' : 'PAVERS AND KERBS'} <br/>{langSelected === 0 ? 'ZAVRŠNI SLOJ' : 'TOP LAYER'}</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'BLOKOVI' : 'BLOCKS'} <br/>{langSelected === 0 ? 'UKRASNI' : 'Decorative'}</p></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>HYDROPHOBE 01</p></th>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>WETOPHOBE W2</p></th>
                  <td className="text-center"><Dots numberOf="5"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>EFFLO-WET 01</p></th>
                  <td className="text-center"></td>
                  <td className="text-center"><Dots numberOf="2"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Aditives3;
