import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/betonska_galanterija.jpeg";

const Automation5 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)
  return (
    <>

      <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="Auto" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'BETONSKE KONSTRUKCIJE I GALANTERIJA' : 'CONCRETE STRUCTURES AND RELATED GOODS'}</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Izrada betonske galanterije (opločnik, betonski panel i ploča, rubnik i sl.), u raznim bojama je složen proces, a organizacija i postavke software-a direktno utječu na kvalitetu proizvoda. 20 godišnje iskustvo uloženo u razvoj proizvodnje betonske galanterije garantira Vam da su Pinky-s software i Pinky-s oprema najbolje rješenje za Vas.' : 'The production of concrete accessories (pavers, concrete panels and slabs, curbs...) in various colours is a complex process and the organization and software settings directly affect the quality of products. 20 years’ experience invested in the development and production of concrete accessories, gives a guarantee that Pinky-s software and equipment are the best solution for you.'}</p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Automation5;
