
import {useFontSizeStore} from "../stores/useFontSizeStore";
import React from "react";

const NewsDetail = ({head}) => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <>

      <div className="row mt-3">
        <div className="col-lg-10 col-sm-12">
          <p style={options[textSizeSelected].styleSub} className="mt-0 mb-0">
            {head.date}
          </p>
        </div>
        <div className="col-lg-2 col-sm-12">

        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <p style={options[textSizeSelected].styleNormalBold} className="mt-0 mb-0">{head.headline}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 col-sm-12">
          <p style={options[textSizeSelected].styleNormalLight} className="mt-0 mb-0">
            {head.text}
          </p>
        </div>
        <div className="col-lg-2 col-sm-12">

        </div>
      </div>
      <div className="row mt-1">
        <div className="col-lg-10 col-sm-12">
          <hr/>
        </div>
        <div className="col-lg-2 col-sm-12">

        </div>
      </div>

    </>
  );
};

export default NewsDetail;
