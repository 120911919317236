import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Cert1 from '../assets/ISO_9001.jpg'
import Cert2 from '../assets/ISO_14001.jpg'
import Cert3 from '../assets/certifikat_aditivi.jpeg'
import Cert4 from '../assets/certifikat_svi.jpeg'


const Certificates = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  return (
    <div className="container">

      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleOver}>{langSelected === 0 ? 'Certifikati' : 'Certificates'}</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>ISO 9001</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            {langSelected === 0 ? 'Poduzeće PINKY-S d.o.o. uvelo je sustav upravljanja kvalitetom u svrhu postizanja najvišeg mogućeg nivoa kvalitete svojih usluga i proizvoda' : 'The company PINKY-S Ltd. introduced a quality management system in order to achieve the highest possible level of quality services and products.'}
            <br/>
            <br/>
            {langSelected === 0 ? '-Distribucija pigmenata' : '- Distribution of pigments'}
            <br/>
            {langSelected === 0 ? '-Proizvodnja boja i premaza na bazi vode' : '- Manufacture of water based paints and coatings '}
            <br/>
            {langSelected === 0 ? '-Konzalting za pigmente' : '- Consulting regarding pigments'}
            <br/>
            {langSelected === 0 ? '-Proizvodnja suspenzija i disperzija pigmenata' : '- Manufacture of pigment suspension and dispersion of pigments'}
            <br/>
            {langSelected === 0 ? '-Projektiranje, proizvodnja i servisiranje strojeva specijalne namjene u betonskoj industriji.' : '- Design, manufacture and service of special purpose machines in the concrete industry'}
            <br/>
            <br/>
            {langSelected === 0 ? 'Cilj politike kvalitete je potpuno razumijevanje konteksta organizacije, zahtjeva koje smo kao organizacija preuzeli, zahtjeva relevantnih zainteresiranih strana i naročito zahtjeva kupca kojemu pružamo uslugu ili isporučujemo proizvod.' : 'The goal of the organization is to monitor the development of technology in our industry and in cooperation with our customers to constantly increase the level of product quality'}
            <br/>
            <br/>
            {langSelected === 0 ? 'Cilj svih zaposlenih je biti podrška u ostvarenju ciljeva organizacije , te u potpunosti razumjeti zahtjeve naših kupaca i partnera.' : 'The aim of all employees is to be supportive in achieving the objectives of the organization and to fully understand the requirements of our customers and partners'}
            <br/>
            <br/>
            {langSelected === 0 ? 'Za postizanje ciljeva:' : 'To be able to achieve the objectives:'}
            {langSelected === 0 ? '-rukovodimo potrebama kupaca' : '- we respond to customer needs'}
            <br/>
            {langSelected === 0 ? '-uključujemo sve zainteresirane strane' : '- we include all interested parties'}
            <br/>
            {langSelected === 0 ? '-održavamo tehničku suradnju i partnerske odnose s kupcima i dobavljačima' : '- we maintain technical cooperation and partnerships with customers and suppliers'}
            <br/>
            {langSelected === 0 ? '-razvijamo pošten, kulturan i korektan pristup s poslovnim partnerima' : '- we develop a fair, civil and correct approach to business partners'}
            <br/>
            {langSelected === 0 ? '-razvijamo odgovornost, točnost i pravovremenost' : '- we take responsibility, accuracy and timeliness seriously'}
            <br/>
            {langSelected === 0 ? '-težimo stalnom unapređenju, poboljšanju i razvoju' : '- we strive for constant improvement and development '}
            <br/>
            {langSelected === 0 ? '-nastojimo ostvariti kvalitetnu radnu okolinu i zadovoljstvo zaposlenika' : '- we strive to create a quality working environment and ensure employee satisfaction'}
            <br/>
            {langSelected === 0 ? '-potičemo načelo vođenja' : '- we encourage the principle of leadership'}
            <br/>
            <br/>
            {langSelected === 0 ? 'Sustav upravljanja kvalitetom temeljen na zahtjevima norme ISO 9001:2015. održavat ćemo uz pomoć ovlaštene, međunarodno priznate i neutralne certifikacijske ustanove.' : 'The quality management system based on the requirements of ISO 9001:2015. will be maintained with the help of an authorized, internationally recognized and neutral certification institution.'}
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
          <img src={Cert1} className="img-fluid"  alt="ISO 9001 certificate" />
        </div>
      </div>


      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>ISO 14001</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            {langSelected === 0 ? 'PINKY-S d.o.o. se obvezuje štiti okoliš na lokacijama na kojim provodi svoje aktivnosti proizvodnje, pakiranja i skladištenja ili daje usluge primjereno prirodi, opsegu i utjecaju na okoliš vlastitih djelatnosti ili usluga.' : 'PINKY-S d.o.o. commits to protecting the environment at the locations where it carries out its production, packaging and storage activities and will show respect for nature when it carries out its own activities or services.'}
            <br/>
            {langSelected === 0 ? 'Kao organizacija svjesna okoliša obvezujemo se:' : 'As an environmentally conscious organization, we are committed to:'}
            <br/>
            {langSelected === 0 ? '-primjenjivati procese i tehnologiju nadzora za smanjenje zagađivanja do mogućeg opsega' : '- monitoring processes and technology to reduce pollution to the extent possible'}
            <br/>
            {langSelected === 0 ? '-udovoljavati važećim zakonskim zahtjevima i zahtjevima ugovora, te postavljati interne standarde i zahtjeve kada je to potrebno' : '- complying with valid legal requirements and contract requirements and setting internal standards and requirements when necessary'}
            <br/>
            {langSelected === 0 ? '-stalno poboljšavati i sprječavati onečišćenja' : '- preventing pollution to the extent possible '}
            <br/>
            {langSelected === 0 ? '-kada je primjereno provoditi korektivne akcije koje su se pokazale učinkovite' : '- implementing corrective actions that have proven to be effective when appropriate'}
            <br/>
            {langSelected === 0 ? '-nastojati stalno poboljšavati sustav upravljanja okolišem' : '- striving to constantly improve the environmental management system'}
            <br/>
            {langSelected === 0 ? '-pratiti zahtjeve zakona i propisa vezane za procese' : '- following the requirements of laws and regulations related to environmental processes'}
            <br/>
            {langSelected === 0 ? 'Sustav upravljanja okolišem temelji se na zahtjev norme ISO 14001:2015. Izgrađeni sustav upravljanja okolišem održavat ćemo uz pomoć ovlaštene, međunarodno priznate i neutralne certifikacijske ustanove.' : 'The environmental management system is based on the requirements of the ISO 14001:2015 standard. The environmental management system will be maintained  with the help of an authorized, internationally recognized and neutral certification institution.'}
            <br/>
            <br/>
            {langSelected === 0 ? 'Poslovodstvo poduzeća u cijelosti podržava politiku i ciljeve okoliša.' : 'The management of the company fully supports the environmental policy and its goals.'}
            <br/>
            {langSelected === 0 ? 'Dužnost je svakog djelatnika poduzeća da primjenjuje postupke koji se odnose na njegovo područje.' : 'It is the duty of every company employee to apply the procedures related to their area of expertise.'}
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
          <img src={Cert2} className="img-fluid"  alt="ISO 14001 certificate" />
        </div>
      </div>


      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'Certifikacijom proizvoda do tržišta' : 'Product certification and market access'}</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            {langSelected === 0 ? 'Tvrtka Pinky-s svima je poznata po svojoj dugogodišnjoj tradiciji u betonskoj industriji. Na tržištu se nalazimo već 25 godina i u cilju maksimalnog zadovoljenja potreba naših kupaca odlučili smo upotpuniti naš proizvodni asortiman dodacima za beton. Nakon više godišnjeg istraživanja naš stručni tim inženjera kemije razvio je proizvode upravo za vaše potrebe. U našem asortimanu nalazi se osam novo certificiranih dodataka za transportni beton i predgotovljene betonske elemente. Svaki proizvod smo mjesecima testirali u našem laboratoriju u cilju da se dovedu do savršenstva i da se zadovolje potreba kupaca u betonskoj industriji.' : 'The company Pinky-s is known for its long tradition in the concrete industry, recently celebrating 25 years of trading. In order to meet the needs of our customers, we decided to expand our product range with concrete additives. After several years of research, our expert team of chemical engineers developed the products specifically for our customers’ needs. Our assortment includes eight newly certified additives for ready-mixed/transport concrete and precast concrete elements. The products have been rigorously tested in our laboratory in order to achieve perfection and to meet the needs of our customers in the concrete industry.'}
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
          <img src={Cert3} className="img-fluid"  alt="Certificate" />
        </div>
      </div>



      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'Proizvodi' : 'Products'}</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            RHEOGLIDE L 10
            <br/>
            {langSelected === 0 ? 'Super-plastifikator za transportne betone s produljenim vremenom ugradnje' : 'Super-plasticizer for ready-mixed/transport concrete with extended setting time'}
            <br/>
            <br/>
            RHEOGLIDE L 30
            <br/>
            {langSelected === 0 ? 'Super-plastifikator za transportne betone s produljenim vremenom ugradnje primjeren drobljenim agregatima s manjom količinom finih čestica' : 'Super-plasticizer for ready-mixed/transport concrete with extended setting time suitable for crushed aggregates with a smaller amount of fine particles'}
            <br/>
            <br/>
            RHEOGLIDE L60
            <br/>
            {langSelected === 0 ? 'Ekonomičan i robustan super-plastifikator za transportne betone s produljenim vremenom ugradnje.' : 'Economical and robust super-plasticizer for ready-mixed/transport concrete with extended setting time'}
            <br/>
            <br/>
            RHEOGLIDE F45
            <br/>
            {langSelected === 0 ? 'Super-plastifikator za predgotovljenje betone s ranim prirastom čvrstoća i produljenim vremenom ugradnje' : 'Super-plasticizer for precast concrete with early strength gain and extended installation time'}
            <br/>
            <br/>
            RHEOGLIDE F75
            <br/>
            {langSelected === 0 ? 'Super-plastifikator za predgotovljene betonske elemente s ranim prirastom čvrstoća i produljenim vremenom ugradnje' : 'Super-plasticizer for precast concrete elements with early strength gain and extended installation time'}
            <br/>
            <br/>
            PLASTOGLIDE W63
            <br/>
            {langSelected === 0 ? 'Plastifikator za transportne betone i predgotovljenje betone u svrhu bolje obradivosti' : 'Plasticizer for ready-mixed/ transport concrete and precast concrete for better workability'}
            <br/>
            <br/>
            AIRGLIDE A11
            <br/>
            Aerant
            <br/>
            <br/>
            WETOPHOBE W2
            <br/>
            Aerant
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
          <img src={Cert4} className="img-fluid"  alt="Certificate products" />
        </div>
      </div>

    </div>
  );
};

export default Certificates;
