import Carousel from '../elements/Carousel'
import News from "../news/News";
import LastProjects from "../news/LastProjects";
import MainHeader from "./MainHeader";
import QuickLocation from "./QuickLocation";

const Home = () => {
  return (
    <div>
      <Carousel />
      <MainHeader />
      <QuickLocation />
      <News />
      <LastProjects />
    </div>
  );
};

export default Home;
