import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from '../assets/doziranje_pigmenta.png'

const Equipment4 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  return (
    <>

      <div className="container">
        <div className="row">

          <div className="col-12 mr-1 pr-1">
            <img src={Image1}  style={{ width: '100%', height: '200px', padding:'0px'}} alt="Oprema1" />
          </div>

        </div>


        <div className="row">
          <div className="col-8">
            <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'DOZIRANJE PIGMENATA' : 'DOSING OF PIGMENTS'} </p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Vrsta pigmenta određuje način primjene i doziranja:' : 'A type of pigment determines the method of application and dosage:'}<br/>
              {langSelected === 0 ? '• praškasti' : '• powder'}<br/>
              {langSelected === 0 ? '• granulasti ili' : '• granular or'}<br/>
              {langSelected === 0 ? '• tekući pigment' : '• liquid pigment'}<br/>
            </p>
          </div>
        </div>


        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Pinky-s Vam nudi rješenje za doziranje svih vrsta pigmenata, za mala, srednja i velika postrojenja. Specijalizirali smo se za tekuće pigmente zbog njihove bolje distribucije u sam materijal (beton).' : 'Pinky-s offers a solution for dosing all types of pigments, for small, medium and large plants. We specialize in liquid pigments due to their better distribution in the material itself (concrete).'}  </p>
          </div>
        </div>


        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Doziranje tekućih pigmenata zavisi od količine cementa, materijala i vlažnosti. Imamo rješenje za najzahtjevnije korisnike s mogućnošću doziranja do 8 boja ili 4 boje na 2 betonare na 1 vagi. Cijeli sustav doziranja i miješanja boja je konstruiran i izrađen u Pinky-s d.o.o.' : 'The dosage of liquid pigments depends on the amount of cement, material and humidity. We have a solution for the most demanding users with a possibility of dispensing up to 8 colours or 4 colours to 2 concrete plants on 1 scale. The entire colour dosing and mixing system was designed and manufactured by Pinky-s d.o.o.'}
              <br/>
              {langSelected === 0 ? '• vaga za aditive ili vaga aditiva ili vaga za aditiv' : '• dosing scale for additives'}<br/>
              {langSelected === 0 ? '• vaga za boje ili vaga boje' : '• dosing scale for paint'}<br/>
              {langSelected === 0 ? '• vaga za dodatke' : '• dosing scale for supplements'}<br/>
            </p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Equipment4;
