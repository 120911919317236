import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/tekuci_pigmenti.png";
const PigmentsFluid = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  return (
    <div className="container">
      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'TEKUĆI PIGMENTI ZA BETON' : 'LIQUID PIGMENTS FOR CONCRETE'}</p>
        </div>
        <div className="col-4">

        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 col-sm-12">
          <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="Pigmenti1" />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'Tekući pigmenti – paste' : 'Liquid pigments – paste'}</p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Izraz „tekuća boja“ odnosno pasta, inicijalno se odnosi na suspenziju pigmenta u vodi. Jednostavna suspenzija (mješavina vode i pigmenta), tek nakon obrade (dispergiranjem i turbo-mlinom), te dodavanjem specijalnih aditiva kao što su dispergatori, dodaci za vlaženje, reološki aditivi, antipjenića, te sredstva za konzerviranje, postaje stabilna disperzija.' : 'The term "liquid paint" or paste initially refers to a suspension of pigment in water. A simple suspension (mixture of water and pigment) becomes a stable dispersion only after processing (dispersing and turbo-milling), adding special additives such as dispersants and wetting additives, rheological additives, defoamers and preservatives,.'}
            <br />
              {langSelected === 0 ? 'Većina sinetičkih pigmenata kao što su oksidi željeza, kroma i kobalta mogu biti korišteni za izradu tekućeg pigmenta. Za izradu tekućeg pigmenta, najčešće se koristi praškasti pigment  od kojeg se dispregiranjem uz određenu opremu i dodatke, aglomerati čestica svode na svoju primarnu veličinu.' : 'Most synthetic pigments such as iron, chromium and cobalt oxides can be used to make a liquid pigment. For the production of liquid pigment, powder pigment is most often used; through this process, by dispersing certain equipment and additives, agglomerates of particles are reduced to their primary size.'}
            <br/>
              {langSelected === 0 ? 'Naša receptura u kombinaciji s aditivima omogućava izradu tekućeg pigmenta s visokim sadržajem pigmenta (50-65%), uz zadržavanje stabilne koloristike, fizičkih svojstava, te stabilnosti pri skladištenju. Time je omogućena izrada tekućeg pigmenta u svim mogućim tonovima boje.' : 'Our recipe in combination with the additives enables the production of liquid pigment with a high pigment content (50-65%), while maintaining the stable colour, physical properties, and stability during storage. This enables the production of liquid pigment in all possible colour shades.'}
            <br/>
              {langSelected === 0 ? 'Prednosti tekućeg pigmenta u odnosu na praškasti iskazuju se s mogućnošću preciznog automatskog doziranja, te reduciranjem prašnjavosti.' : 'The possibility of precise automatic dosing and reduction of dustiness are the advantages of the liquid pigment compared to the powder one.'}
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'Karakteristike proizvedenog tekućeg pigmenta' : 'Characteristics of the produced liquid pigment'}</p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Definirane specifikacije za svaku proizvedenu šaržu osiguravaju konstantu u kvaliteti od isporuke do isporuke, kao i u samoj aplikaciji. Te specifikacije sadrže kolorimetrijska svojstva, relativnu moć obojenja, kao i fizička svojstva u koja spadaju viskozitet, pH vrijednost, sadržaj suhe tvari i gustoća.' : 'The defined specifications for each batch produced ensure consistency in quality for every delivery, as well as in the application itself. These specifications include colorimetric properties, relative colouring power, as well as physical properties including the viscosity, pH value, dry matter content and density.'}
            <br/>
              {langSelected === 0 ? 'Sukladnost specifikacijama svake proizvedene šarže tekućeg pigmenta osigurana je internom kontrolom kvalitete prema standardu ISO 9001:2015.' : 'Compliance with the specifications of each batch of liquid pigment produced is ensured by the internal quality control according to the ISO 9001:2015 standard.'}
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>


      <div className="row mt-4">
        <div className="col-12">
          <p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'Najčešća upotreba' : 'Most common use'}</p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Tekući pigment najčešće služi obojenju betona u industriji proizvodnje betonskih opločnika, betonskih blokova, betonskog crijepa, rubnjaka te ostalih nearmiranih betonskih elemenata. Za tu namjenu koriste se isključivo sintetički oksidi željeza, kroma i kobalta, jer na taj način osiguravamo izuzetnu otpornost na vremenske utjecaje, UV stabilnost, te otpornost na kiseline i lužine.' : 'The liquid pigment is most often used to colour concrete in industries relating to the manufacture of concrete pavers, concrete blocks, concrete tiles, curbs and other non-reinforced concrete elements. For this purpose, only synthetic iron, chromium and cobalt oxides are used, ensuring exceptional resistance to weather influences, UV stability, and resistance to acids and alkalis.'}
            <br/>
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'Pakiranje' : 'Packaging'}</p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Dostupna su pakiranja u plastičnim bačvama sadržaja od 200kg do IBC kontejnera od 1000kg.' : 'The product is packaged in 200 kg plastic barrels and IBC 1000 kg containers are also available.'}
            <br/>
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>


    </div>
  );
};

export default PigmentsFluid;
