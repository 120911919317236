import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from '../assets/automatsko_nanosenje.png'
import Image3 from '../assets/linija za bojanje1_300.jpeg'
import Image4 from "../assets/linija za bojanje2_300.jpeg";

const Equipment7 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  return (
    <>

      <div className="container">

        <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="Vlaga5" />

        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'AUTOMATSKO NANOŠENJE PREMAZA' : 'AUTOMATIC COATING APPLICATION'} </p>
          </div>
        </div>


        <div className="row mt-2">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'Airless sistem za nanošenje zaštitnih premaza na betonske proizvode' : 'Airless system for applying protective coatings to concrete products'} </p>
          </div>
        </div>

        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Sistem je prije svega namijenjenih nanošenju zaštitnih premaza u proizvodnji betonskih opločnika i betonskih ploča. Moguće ga je postaviti na poziciju odmah nakon izlaska iz preše ili na poziciju prije pakiranja.' : 'The system is primarily intended for the application of protective coatings in the production of concrete pavers and concrete slabs. It is possible to place it in the position immediately after coming off the press or before packaging.'}<br/>
              {langSelected === 0 ? 'Mogućnost ovisi o ostalim zahtjevima koje proizvod i materijal moraju zadovoljiti.' : 'The chosen possibility depends on other requirements that the product and material must meet. '}
              {langSelected === 0 ? 'Airless sistem namijenjenih je isključivo za nanošenje materijala koji ne sadrže otapala.  Zbog zaštite radne okoline i smanjenog rasipa materijala sistem radi na malom radnom pritisku bez potrebnog zraka za raspršivanje.  Sistem je univerzalni, ali konstrukcija ovisi o tipu proizvodnje i najboljoj poziciji u liniji proizvodnje.' : 'The airless system is intended exclusively for the application of materials that do not contain solvents. Due to the protection of the working environment and reduced wastage of materials, the system works at a low working pressure without necessary air for spraying. The system is universal, but the construction depends on a type of production and the best position in the production line.'}
            </p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'Karakteristike sistema:' : 'Features of the system:'} </p>
          </div>
        </div>

        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? '- Radni tlak 2-4 bara' : '- working pressure 2-4 bar'}<br/>
              {langSelected === 0 ? '- Širina nanošenja 120 cm' : '- application width 120 cm'}<br/>
              {langSelected === 0 ? '- Količina nanošenja regulira se veličinom dizne' : '- amount of application regulated by the size of the nozzle'}<br/>
              {langSelected === 0 ? '- Isporučuju se tri kompeta leptir dizni' : '- three sets of butterfly nozzles supplied'}<br/>
              {langSelected === 0 ? '- S obzirom da je zatvoren sistem nema zasušivanja u cjevovodu' : '- given that the system is closed, there is no drying in the pipeline'}<br/>
              {langSelected === 0 ? '- Sistem za automatsko pranje dizni za vrijeme pauze' : '- system for automatic washing of nozzles during breaks'}<br/>
              {langSelected === 0 ? '- U sistemu su 4 (četri) airless pištolja za nanos sa mogućnošću radnog tlaka do 210 bara' : '-  4 (four) airless application guns with the possibility of the working pressure of up to 210 bar'}<br/>
              {langSelected === 0 ? '- Jednostavan ručni sistem za postavljanje širine nanošenja' : '- simple manual system for setting the application width'}<br/>
              {langSelected === 0 ? '- Električno pozicioniranje visine stroja' : '- electrical positioning of the machine height'}<br/>
              {langSelected === 0 ? '- Pumpa je opremljena sa sistemom za smanjenje pulsiranja' : '- pump equipped with a pulsation reduction system'}<br/>
              {langSelected === 0 ? '- Leptir dizne omogućuju brzu promjenu i eventualno čišćenje sa okretanje leptira' : '- butterfly nozzles enabling a quick change and possible cleaning by turning the butterfly'}<br/>
              {langSelected === 0 ? '- Automatsko upravljanje sa početkom i krajem nanošenja pomoću PLC-a i panela za upravljanje' : '- automatic control with the beginning and end of application using PLC and control panel'}<br/>
            </p>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'Akrilni pemazi za betonski crijep' : 'Acrylic coatings for concrete roof tiles'} </p>
          </div>
        </div>

        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Nanošenje akrilnih premaza na betonski crijep zahtjevni je proces, i upravo iz tog razloga svojim smo kupcima smo htjeli pomoći da kvalitetno apliciraju naš akrilni premaz. Iskustvo nam je pomoglo da kvalitetno riješimo problem nanošenja akrilnog premaza, a rad na projektu stroja za nanošenje omogućio nam je da još bolje projektiramo naš premaz za betonski crijep. Preuzimajući odgovornost za premaz i nanošenje premaza naš kupac je siguran u kvalitetu svog proizvoda.' : 'Knowing that applying acrylic coatings to concrete roof tiles is a demanding process, we wanted to help our customers to apply our acrylic coating efficiently and effectively. Our experience helped us to solve the problem of how to apply the high quality acrylic coating, and working on an application machine allowed us to come up with an even better process for coating concrete roof tiles. Taking responsibility for coating and applying the coating, our customer can be confident in the quality of their product.'}
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-1">

          </div>
          <div className="col-lg-5 col-12 mr-2 pr-2">
            <img src={Image3}  style={{ width: '100%', height: '400px', padding:'0px'}} alt="Bojanje3" />
          </div>
          <div className="col-lg-5 col-12 ml-2 pl-2">
            <img src={Image4}  style={{ width: '100%', height:'400px', padding:'0px'}} alt="Bojanje4" />
          </div>
          <div className="col-lg-1">

          </div>
        </div>


        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Komore za špricanje opremljene su filterima za oprašivanje, a nanos premaza može biti:' : 'The spraying chambers are equipped with pollination filters, and the application of paint coating can be:'}<br/>
              - airless<br/>
              - airmix<br/>
              <br/>
              {langSelected === 0 ? 'Osim za nanošenje premaza na betonski crijep projektirali smo i izradili stroj za:' : 'We have also designed and built machines for:'}<br/>
              {langSelected === 0 ? '- nanošenje impregnacije na betonske opločnike nakon izlaska iz stroja i' : '- application of impregnation to concrete pavers after leaving the machine '}<br/>
              {langSelected === 0 ? '- stroj za impregnaciju paleta pri izradi opločnka' : '- for impregnating pallets when making paving slabs'}
            </p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Equipment7;
