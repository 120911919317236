import React from 'react';
import logo from '../assets/logo4.png'
import {Link, NavLink} from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import SelectTextSize from "../common/SelectTextSize";
import {useFontSizeStore} from "../stores/useFontSizeStore";
import ReactCountryFlag from "react-country-flag"

const Navbar = (props) => {
  // const {location} = props
  const langSelected = useFontSizeStore((state) => state.lang)
  const setCroatian = useFontSizeStore((state) => state.setCroatian)
  const setEnglish = useFontSizeStore((state) => state.setEnglish)

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-custom">
        <div className="container">
          <Link to="/" className="navbar-brand"><img src={logo} alt='Pinkys logo'/></Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <FontAwesomeIcon icon={faBars} style={{color: 'white'}}/>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink
                exact
                activeClassName="navbar__link--active"
                className="navbar__link"
                to="/"
              >
                Home
              </NavLink>

            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="navbar__link--active"
                className="navbar__link"
                to="/pigments"
              >
                {langSelected === 0 ? 'Pigmenti' : 'Pigments'}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="navbar__link--active"
                className="navbar__link"
                to="/aditives"
              >
                {langSelected === 0 ? 'Aditivi' : 'Additives'}
              </NavLink>

            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="navbar__link--active"
                className="navbar__link"
                to="/equipment"
              >
                {langSelected === 0 ? 'Oprema' : 'Equipment'}

              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                activeClassName="navbar__link--active"
                className="navbar__link"
                to="/automation"
              >
                {langSelected === 0 ? 'Automatika' : 'Automation'}
              </NavLink>
            </li>
            <li className="nav-item">
              <button  type="button" className="btn btn-link mt-0 mb-0 pt-0 pb-0" onClick={() => setCroatian()}><ReactCountryFlag countryCode="HR" /></button>
            </li>
            <li className="nav-item">
              <button type="button" className="btn btn-link mt-0 mb-0 pt-0 pb-0" onClick={() => setEnglish()}><ReactCountryFlag countryCode="GB" /></button>
            </li>
          </ul>
          <SelectTextSize />
        </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

