import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";

const OurTeam = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  return (
    <div className="container">
      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleOver}>{langSelected === 0 ? 'Naš tim' : 'Our team'}</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            {langSelected === 0 ? 'Direktor:' : 'Director:'}
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Milivoj Koprivec
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7001
          </p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            {langSelected === 0 ? 'Računovodstvo i financije:' : 'Accounts and finance:'}
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Spomenka Koprivec
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7002
          </p>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            {langSelected === 0 ? 'Laboratorij:' : 'Laboratory:'}
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Danijel Šinko
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7006
          </p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>

          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Nataša Sekulić
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7005
          </p>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            {langSelected === 0 ? 'Odjel automatizacije:' : 'Automation Department:'}
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Dejan Kralj
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7009
          </p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>

          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Jura Jurović
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7008
          </p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>

          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Matija Novak
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7003
          </p>
        </div>
      </div>


      <div className="row mt-5">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            {langSelected === 0 ? 'Komercijala:' : 'Commercial Department:'}
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Danijel Šinko
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7006
          </p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>

          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Milivoj Koprivec
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7001
          </p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-3 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>

          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            Ivana Pejić Novak
          </p>
        </div>
        <div className="col-lg-2 col-sm-4">
          <p style={options[textSizeSelected].styleNormal}>
            091 \ 360 - 7004
          </p>
        </div>
      </div>



    </div>
  );
};

export default OurTeam;

