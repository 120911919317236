import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";

const CookiePolicy = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (
    <div className="container">

      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleOver}>Primjena kolačića</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Na web-stranicama http://pinky-s.com koriste se tzv. kolačići (cookies) – tekstualne datoteke koje na računalo korisnika/posjetitelja smješta web poslužitelj kojim se posjetitelj koristi. Datoteke nastaju kada preglednik na uređaju korisnika učita web stranice koje je posjetio, koje potom šalju podatke pregledniku koji zatim izrađuje tekstualnu datoteku (kolačić). Preglednik dohvaća i šalje datoteku na poslužitelj web stranica (mjesta, stranice) prilikom povratka korisnika na njega te se tako korisnicima pruža više online mogućnosti i bolji korisnički doživljaj.
            <br/>
            Bitno je naglasiti da http://pinky-s.com internet stranica ne može pristupiti drugim datotekama na računalu korisnika ili dobiti pristup informacijama koje korisnik ranije nije dao. Web stranice http://pinky-s.com ne sadržavaju kolačiće koji omogućuju pokretanje programa ili postavljanje virusa na Vaše računalo.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Podaci koji se prikupljaju uključuju IP adresu korisnika, podatke o pregledniku, jeziku, operativnom sustavu i druge standardne statističke podatke koji se prikupljaju i analiziraju isključivo u anonimnom i masovnom obliku. Web stranice http://pinky-s.com služe se Google Analytics i Facebook Pixel statistikom. Podatke o tome kako se korisnici koriste našim web stranicama povremeno možemo prikupljati koristeći se i drugim alatima sličnim Google Analytics poput Facebook Pixel.
            <br/>
            Također, u svrhu planiranja objava sadržaja primjerenih Vašim interesima i budućih marketinških kampanja koristimo alat Google Analytics Demographics and Interest Reporting i sl. gdje možemo upotrebljavati podatke iz njihovih oglašavanja na temelju interesa ili podataka o publici treće strane (npr. dob, spol i interesi) na usluzi Google Analytics ili Facebook Pixel. Detaljne informacije treće strane o ovoj usluzi, kao i o mogućnostima korisnika da reguliraju postavke kolačića koji su za to nužni, dostupne su na: http://www.google.com/analytics/learn/privacy.html i https://www.facebook.com/business/help/471978536642445.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Ako niste suglasni upotrebom kolačića, možete ih lako izbrisati (ili spriječiti) na vašem računalu ili mobilnom uređaju pomoću postavki Vašeg internet preglednika.
            <br/>
            Kako je svrha kolačića poboljšanje i omogućivanje upotrebe našeg web-mjesta i njegovih procesa, imajte na umu da sprječavanjem ili brisanjem kolačića možete onemogućiti funkcioniranje značajki ovih stranica ili prouzročiti njihov drugačiji rad i izgled u vašem pregledniku.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Upotrebom ovih web-stranica suglasni ste s načinom prikupljanja i upotrebe podataka opisanim u ovoj Izjavi te pohranom i pristupom kolačićima na vašem uređaju.
            <br/>
            Kada prvi put pristupite web stranici, pojavit će se natpis koji upozorava na postojanje kolačića i zatražiti Vašu suglasnost za njihovo prihvaćanje. Nastavkom pregledavanja web stranice, prihvaćate korištenje kolačića. Ukoliko korištenje kolačića nije omogućeno na vašem uređaju, kupovina preko http://pinky-s.com Internet trgovine niti formiranje liste želja (wishlist) neće biti moguće.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
        </div>
      </div>



      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Privremeni kolačići (sesije)
            <br/>
            Automatski se uklanjaju s vašeg računala kada zatvorite web preglednik. Pomoću njih web mjesta pohranjuju privremene podatke (npr. stavke u košarici za kupnju).
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Stalni kolačići
            <br/>
            Ostaju spremljeni na vašem računalu i nakon zatvaranja web preglednika. Pomoću njih web stranice pohranjuju podatke – najčešće je riječ o vašim postavkama kod korištenja stranice. Stalni kolačići će ostati na vašem računalu tako dugo dok ih ručno ne uklonite ili dok im ne istekne rok.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Kolačići prve strane
            <br/>
            Dolaze s web mjesta kojeg gledate, a to su privremeni ili stalni. Pomoću njih web mjesta pohranjuju podatke kao što su ime i lozinka, s ciljem da se ne morate prijavljivati prilikom svakog posjeta.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Kolačići treće strane
            <br/>
            Pripadaju različitim domenama od onog prikazanog u adresnoj traci. Web stranice mogu sadržavati sadržaj iz drugih područja (kao što su banneri), što otvara mogućnost za praćenje korisničke povijesti pregledavanja. Mogućnosti podešavanja privatnosti u većini modernih preglednika omogućuju blokiranje kolačića treće strane.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            Kako onemogućiti kolačiće?
            <br/>
            Želite li onemogućiti spremanje kolačića na svoje računalo, možete to učiniti. Sam čin blokiranja mogao bi imati negativan učinak na korištenje web stranice. Kako bi isključili kolačiće, potrebno je namjestiti postavke i konfiguracije vašeg internetskog preglednika. U izborniku preglednika odaberite pomoć i informacije o kolačićima te slijedite upute.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            PINKY-S  d.o.o. se obavezuje pružati zaštitu osobnim podacima kupaca, na način da prikuplja samo nužne, osnovne podatke o kupcima/korisnicima koji su nužni za ispunjenje naših obveza; informira kupce o načinu korištenja prikupljenih podataka, redovito daje kupcima mogućnost izbora o upotrebi njihovih podataka, uključujući mogućnost odluke žele li ili ne da se njihovo ime ukloni s lista koje se koriste za marketinške kampanje.
            <br/>
            Svi se podaci o korisnicima strogo čuvaju i dostupni su samo djelatnicima kojima su ti podaci nužni za obavljanje posla. Svi djelatnici PINKY-S  d.o.o. i poslovni partneri odgovorni su za poštivanje načela zaštite privatnosti.
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">
        </div>
      </div>

    </div>
  );
};

export default CookiePolicy;
