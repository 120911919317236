import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";


const Aditives2 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)
  return (
    <>

      <div className="container">

        <div className="row mt-1">
          <div className="col-lg-12 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              <br/>
              {langSelected === 0 ? 'Današnja proizvodnja betona nezamisliva je bez upotrebe kemijskih dodataka za beton. Kemijski dodaci direktno utječu na kvalitetu betona, neka svojstva betona  i smanjenje štetnih utjecaja na okoliš. Za različite namjene betona koristimo različite vrste kemijskih dodataka.' : 'Today’s concrete production is unthinkable without the use of chemical concrete admixtures. Chemical admixtures directly affect the quality of concrete, some properties of concrete and the reduction of harmful effects on the environment. We use different types of chemical admixtures for a different purpose.'}<br/>
              {langSelected === 0 ? 'Kemijski dodaci počinju se  koristiti 1931. godine kada su dodaci korišteni da bi se poboljšala neka svojstva betona.' : ' The chemical admixtures began to be used in 1931 when the admixtures were used to improve some properties of concrete.'}<br/><br/>
              {langSelected === 0 ? 'Razvoj kemijskih dodataka kroz povijest omogućio je da se beton počinje koristiti sve više u građevinama različitih namjena.  Ozbiljnijim korištenjem kemijskih dodataka beton je značajno zamijenio čelik u konstrukcijama zahtjevnih objekata.' : '.The development of chemical admixtures throughout history has made it possible for concrete to be used more and more in buildings for various purposes. With more serious use of chemical admixtures, the concrete has significantly replaced steel in the construction of demanding structures.'}<br/><br/>
              {langSelected === 0 ? 'S obzirom da je prošao period duži od 100 godina, mnoga istraživanja propituju efikasnost betona u smislu trajnosti proizvoda, te se upotreba betona našla pred velikim izazovom.' : 'As more than 100 years have passed since the introduction of concrete, many studies have questioned the efficiency of concrete in terms of the product durability; the use of concrete has faced a major challenge'}<br/><br/>
              {langSelected === 0 ? 'Kemijski dodaci novih generacija mogu omogućiti svojstva betona koja će garantirati dugogodišnju trajnost betona u različitim uvjetima eksploatacije' : 'The new generation of chemical admixtures can provide concrete properties that will guarantee the long-term durability of concrete in different operating conditions.'}<br/><br/>
              {langSelected === 0 ? 'PINKY-S tim za  „istraživanje i razvoj“ postavio je za cilj  razvoj kemijskih dodatke koji će  djelovati na kvalitetu i trajnost betona, kao i na štetni utjecaj proizvodnje betona na okoliš.' : 'The PINKY-S research and development team have set itself a goal to develop chemical admixtures that will improve the quality and durability of concrete, as well as reduce the harmful impact of concrete production on the environment.'}
            </p>
          </div>

        </div>



      </div>
    </>
  );
};

export default Aditives2;
