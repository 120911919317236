import './App.css';
import { Switch, Route } from 'react-router-dom'

import Navbar from "./components/global/Navbar";
import Footer from "./components/global/Footer";
import Home from "./components/common/Home";
import AboutUs from "./components/common/AboutUs";
import Careers from "./components/common/Careers";
import Certificates from "./components/common/Certificates";
import Contacts from "./components/common/Contacts";
import OurTeam from "./components/common/OurTeam";
import Support from "./components/common/Support";
import TermsOfUse from "./components/common/TermsOfUse";
import CookiePolicy from "./components/common/CookiePolicy";
import Aditives from "./components/aditives/Aditives";
import Automation from "./components/automation/Automation";
import Equipment from "./components/equipment/Equipment";
import Pigments from "./components/pigments/Pigments";
import News from "./components/news/News";
import LastProjects from "./components/news/LastProjects";
import EUProjects from "./components/news/EUProjects";
import ScrollToTop from "./ScrollToTop";

function App() {
  return (
    <div className="App">
      <Navbar />

      <ScrollToTop>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/aboutus' component={AboutUs} />
          <Route path='/careers' component={Careers} />
          <Route path='/certificates' component={Certificates} />
          <Route path='/contacts' component={Contacts} />
          <Route path='/ourteam' component={OurTeam} />
          <Route path='/support' component={Support} />
          <Route path='/news' component={News} />
          <Route path='/aditives' component={Aditives} />
          <Route path='/automation' component={Automation} />
          <Route path='/equipment' component={Equipment} />
          <Route path='/pigments' component={Pigments} />
          <Route path='/lastprojects' component={LastProjects} />
          <Route path='/termsofuse' component={TermsOfUse} />
          <Route path='/cookiepolicy' component={CookiePolicy} />
          <Route path='/euprojects' component={EUProjects} />
        </Switch>
      </ScrollToTop>
      <Footer />
    </div>
  );
}

export default App;
