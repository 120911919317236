import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from '../assets/disolveri_mesalice.png'

const Equipment9 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)
  return (
    <>

      <div className="container">
        <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="Oprema8" />

        <div className="row">
          <div className="col-8">
            <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'DISOLVERI, MJEŠALA, MLINOVI...' : 'DISOLVERS, MIXERS, MILLS...'} </p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Pinky-s ima u svojoj ponudi opremu koja se koristi u industriji boje i lakova, te farmaceutskoj industriji, a proizvedena je u našoj partner firmi sa sjedištem u Kini. S obzirom da je već prošlo nekoliko godina od prvog takvog stroja na našem tržištu, možemo reći da su zadovoljili svojom kvalitetom i funkcionalnošću.' : 'Pinky-s offers equipment that is used in the paint and coatings industry as well as the pharmaceutical industry. The equipment is manufactured by our partner company based in China. Having been on the market for a several years, they have proven their quality and functionality.'}
              <br/>
              {langSelected === 0 ? 'Do sada je Pinky-s instalirao više od 200 strojeva iz te proizvodnje i za njih strojeve Pinky-s pruža vlastiti servis i rezervne dijelove. To su sljedeći strojevi:' : 'Until now Pinky-s has installed more than 200 machines from that manufacturing range. The service and spare parts for these machines are also provided by us. These are the following machines:'}
              <br/>
              {langSelected === 0 ? '• horizontalni mlinovi' : '• horizontal mills'}<br/>
              {langSelected === 0 ? '• vertikalni mlinovi' : '• vertical mills'}<br/>
              {langSelected === 0 ? '• disolveri' : '• dissolvers'}<br/>
              {langSelected === 0 ? '• shakeri' : '• shakers'}<br/>
              {langSelected === 0 ? '• dispenzeri' : '• dispensers'}<br/>
              {langSelected === 0 ? '• mješala ...' : '• mixers ...'}
            </p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Equipment9;
