import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Dots from "../elements/Dots";
import Image1 from '../assets/aeranti.jpeg'
import Image2 from '../assets/superplastifikator.png'
import Image3 from '../assets/airant.png'

const Aditives4 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)
  return (
    <>
      <div className="container">

        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'DODACI ZA TRANSPORTNE BETONE I BETONE ZA PREDGOTOVLJENE BETONSKE ELEMENTE' : 'ADMIXTURES FOR TRANSPORTATION OF CONCRETE AND CONCRETE FOR PRECAST CONCRETE ELEMENTS'}</p>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'Superplastifikatori i plastifikatori za transportni beton' : 'Superplasticizers and plasticizers for transportation of concrete'}</p>
          </div>
        </div>
        <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}}  alt="Plastifikator1" />

        <div className="row mt-2">
          <div className="col-12">
            <div className="table-responsive-sm">
              <table className="table table-bordered">
                <thead>
                <tr>
                  <th scope="col" className="text-left"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Transportni beton' : 'Transportation of concrete'}</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Redukcija vode' : 'Water reduction'}</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Držanje konzistencije' : 'Maintaining consistency'}</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Rane čvrstoće' : 'Early strenght'}</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Robusnost' : 'Robustness'}</p></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>RHEOGLIDE L30</p></th>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                  <td className="text-center"><Dots numberOf="3"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>RHEOGLIDE L60</p></th>
                  <td className="text-center"><Dots numberOf="3"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                  <td className="text-center"><Dots numberOf="3"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>RHEOGLIDE L10</p></th>
                  <td className="text-center"><Dots numberOf="5"/></td>
                  <td className="text-center"><Dots numberOf="3"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="3"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>PLASTOGLIDE W 63</p></th>
                  <td className="text-center"><Dots numberOf="1"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="1"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div className="row mt-2">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'Superpastifikatori za predgotovljene betonske elemente' : 'Superplasticizers for precast concrete elements'}</p>
          </div>
        </div>

        <img src={Image2} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}}  alt="Plastifikator2" />

        <div className="row mt-2">
          <div className="col-12">
            <div className="table-responsive-sm">
              <table className="table table-bordered">
                <thead>
                <tr>
                  <th scope="col" className="text-left"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Betonski elementi' : 'Concrete elements'} </p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Redukcija vode' : 'Water reduction'}</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Držanje konzistencije' : 'Maintaining consistency'}</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Rane čvrstoće' : 'Early strenght'}</p></th>
                  <th scope="col" className="text-center"><p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Konačne čvrstoće' : 'Robustness'}</p></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>RHEOGLIDE F 45</p></th>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="1"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>RHEOGLIDE F 75</p></th>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="2"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>RHEOGLIDE F 90 (SCC)</p></th>
                  <td className="text-center"><Dots numberOf="3"/></td>
                  <td className="text-center"><Dots numberOf="3"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                </tr>
                <tr>
                  <th scope="row" className="text-left"><p style={options[textSizeSelected].styleNormal}>RHEOGLIDE F 105</p></th>
                  <td className="text-center"><Dots numberOf="5"/></td>
                  <td className="text-center"><Dots numberOf="4"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                  <td className="text-center"><Dots numberOf="5"/></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div className="row mt-4">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'Dodaci za uvlačenje mikro pora zraka u beton' : 'Admixtures for entraining micro air pores into concrete'}</p>
          </div>
        </div>

        <img src={Image3} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}}  alt="Plastifikator3" />

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormalBold}>
              AIRGLIDE A 11
            </p>
          </div>
        </div>
        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Sintetički kemijski dodatak za uvlačenje mikro  pora zraka u beton iz razloga postizanja veće otpornosti na zajednički utjecaj mraza i soli.' : 'Synthetic chemical admixture for entraining micro pores of air into concrete to achieve a greater resistance to the combined effects of frost and salt.'}<br/>
              {langSelected === 0 ? 'Kompatibilan sa svim vrstama superplastifikatora i plastifikatora.' : 'Compatible with all types of superplasticizers and plasticizers.'}
            </p>
          </div>
        </div>


        <div className="row mt-4">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'Dodaci za usporavanje vezanja betona i držanje konzistencije' : 'Admixtures for slowing down the setting of concrete and maintaining consistency'}</p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormalBold}>
              RHEOSNAIL P
            </p>
          </div>
        </div>
        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Usporivač vezanja betona sa svojstvom plastificiranja betona.  Koristi se kod visokih temperatura betona i okoline u skladu s maksimalnom temperaturom betona koja je zadana u normi EN 206-1.' : 'Concrete setting retarder with the property of plasticizing concrete. Used when the temperature of concrete and ambient is high in accordance with the maximum concrete temperature specified in EN 206-1. '}<br/>
              {langSelected === 0 ? 'Kompatibilan sa svim vrstama superplastifikatora i plastifikatora' : 'Compatible with all types of superplasticizers and plasticizers'}
            </p>
          </div>
        </div>




        <div className="row mt-4">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'Dodaci ubrzavanje vezanja betona u hladnim uvjetima' : 'Admixtures for speeding up the setting of concrete in cold conditions'} </p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormalBold}>
              RHEOFRIZ W
            </p>
          </div>
        </div>
        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Ubrzivač vezanja omogućuje ubrzavanje vezanja betona u hladnim uvjetima okoline. Ubrzivač će svoju funkciju imati kod niskih temperatura samo ako je temperatura betona u skladu EN 206-1.' : 'Concrete setting retarder with the property of plasticizing concrete. Used when the temperature of concrete and ambient is high in accordance with the maximum concrete temperature specified in EN 206-1.'}
            </p>
          </div>
        </div>

      </div>

    </>
  );
};

export default Aditives4;
