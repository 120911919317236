import ImageEu from '../assets/EU-projekt.jpeg'
import ImageEU2 from '../assets/montirana3.jpg'
import ImageEU3 from '../assets/montirano.jpeg'

import {useFontSizeStore} from "../stores/useFontSizeStore";

const EUProjects = () => {
  const langSelected = useFontSizeStore((state) => state.lang)
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)

  return (

    <div className="container">
      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleOver}>{langSelected === 0 ? 'EU projekti' : 'EU Projects'}</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'Informatizacija poslovanja poduzeća PINKY-S d.o.o.' : 'Computerization of operations of the company PINKY-S d.o.o.'} </p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Poduzeće Pinky-s d.o.o. renominirano je poduzeće s dugogodišnjom tradicijom u industriji betona. Razvojem poduzeća vlastita proizvodnja aditiva te automatizacija sustava betonara postala je primarna djelatnost. Dugogodišnje iskustvo vezano uz problematiku i tehnologije pri proizvodnji betona omogućilo je da izvedemo tehničko-tehnološka rješenja za bilo koji problem vezan uz betonsku industriju, koristeći se pri tome vlastitim inovacijama, programima i opremom.' : 'The company Pinky-s d.o.o. is a renowned company with a long tradition in the concrete industry. As the company developed, its own production of additives as well as the automation system of concrete plants became its primary activities. Many years of experience related to the issues and technologies in the production of concrete have enabled us to implement the technical-technological solutions for any problem related to the concrete industry, using our own innovations, programs and equipment.'}
          </p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'U rujnu 2019. godine, započeli smo s provedbom projekta „Informatizacija poslovnih procesa poduzeća Pinky-s d.o.o.“, koji je sufinanciran iz natječaja „Poboljšanje konkurentnosti i učinkovitosti MSP-a kroz informacijske i komunikacijske tehnologije (IKT) - 2“. Provedba projekta traje do ožujka 2021. godine. Ukupna vrijednost projekta iznosi 417.684,86 HRK, od toga dobivena bespovratna sredstva iznose 243.555,04 HRK.' : 'In September 2019, we started implementing a project, "Computerization of business processes of the company Pinky-s d.o.o.", which was co-financed through a tender "Improving the competitiveness and efficiency of SMEs through information and communication technologies (ICT) - 2". The implementation of the project lasted until March 2021. The total value was HRK 417,684.86, of which the received grant amounted to HRK 243,555.04.'}
          </p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Ulaganjem u informatizaciju poslovanja kroz uvođenje ERP sustava i proces automatizacije betonara  povezani su poslovni procesi poduzeća te se na taj način utjecalo na smanjenje troškova, povećanu produktivnost, olakšani su rutinski poslovi, informacije su lako dostupne te se omogućilo brže donošenje odluka.' : 'Investing in business computerization through the introduction of ERP systems, as well as the automation system of concrete plants, connects the company\'s business activities, thus reducing costs, increasing productivity, facilitating routine tasks and making information easily available, enabling faster decision-making.'}
          </p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Aktivnosti projekta uključuju nabavu ERP sustava te informatizaciju procesa automatizacije betonara potrebne za modernizaciju poslovanja. Projektom će se utjecati na povećanje prihoda od prodaje i te otvaranje novih radnih mjesta do 2023. godine.' : 'The project activities include a procurement of an ERP system and computerization of the automation process of the concrete plant, necessary for the modernization of the business. The project will influence an increase in sales revenue and the creation of new jobs by 2023.'}
          </p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Za sve dodatne informacije slobodno se obratite na kontakt:' : 'For any additional information, feel free to contact:'}
          </p>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>Spomenka Koprivec, pinky-s@pinky-s.com, 040/ 360 700</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Projekt je sufinancirala Europska unija iz Europskog fonda za regionalni razvoj.' : 'The project was co-financed by the European Union from the European Fund for Regional Development.'}</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal} onClick={() => openInNewTab('https://www.strukturnifondovi.hr')}> www.strukturnifondovi.hr</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal} onClick={() => openInNewTab('https://strukturnifondovi.hr/eu-fondovi/esi-fondovi-2014-2020/op-konkurentnost-i-kohezija/')}>www.strukturnifondovi.hr/op-konkurentnost-i-kohezija-2014-2020-779</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <img src={ImageEu} className="img-fluid"  alt="EUfonds" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleSub}>09/2019.</p>
        </div>
      </div>


      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'Povećanje proizvodnih kapaciteta provedbom projekta iz natječaja „E-impuls“' : 'Increasing production capacity through the implementation of the "E-impulse" tender project'}</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Sa zadovoljstvom Vas obavještavamo da provodimo projekt „Povećanje konkurentnosti poduzeća Pinky-S d.o.o. uvođenjem modernizirane opreme u proizvodni proces“ sufinanciran u okviru Javnog poziva E-impuls. Ukupna vrijednost projekta iznosi 340.281,63 HRK, dok je dobiveni iznos bespovratnih sredstva 228.669,25 HRK. Provedba projekta započela je 1. listopada 2017. godine te traje do 1. listopada 2018. godine.' : 'We are pleased to inform you that we are implementing the project "Increasing the competitiveness of the company Pinky-S d.o.o. by introducing modernized equipment into the production process". The project is co-financed through a public tender E-impuls. The total value of the project is HRK 340,281.63, while the amount received in grants is HRK 228,669.25. The implementation of the project started on October 1, 2017 and will continue until October 1, 2018.'}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Kroz 12. mjeseci trajanja projekta, povećat ćemo naše proizvodne kapacitete u proizvodnji aditiva, odnosno dodataka za betonsku galanteriju za koju koristimo posebnu recepturu razvijenu i testiranu u vlastitom laboratoriju. Cilj projekta je uvođenjem nove opreme u proizvodni proces ostvariti pokazatelje projekta – očuvanje radnih mjesta, otvaranje novog radnog mjesta te povećanje prihoda od prodaje za 21,55 %.' : 'During the 12 months of the project, we will increase our production capacity of additives for concrete accessories, for which we use a special recipe developed and tested in our own laboratory. The aim of the project is to introduce new equipment into the production process, preserve jobs, create more jobs and increase sales revenue by 21.55%.'}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'U pripremi i provedbi projektnih aktivnosti sudjeluju vanjski stručnjaci iz REDEA-e koji posjeduju dugogodišnje iskustvo u pripremi i provođenju projekata, provođenju postupka javne nabave, kao i savjetovanju poduzetnika.' : 'The preparation and implementation of project activities involves external experts from REDEA who have many years of experience in the preparation and implementation of  similar projects, the implementation of public procurement procedures, as well as in advising entrepreneurs.'}</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Za sve dodatne informacije slobodno nam se obratite:' : 'For any additional information, feel free to contact us:'}</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>Spomenka Koprivec, pinky-s@pinky-s.com, 040/ 360 700</p>
          <p style={options[textSizeSelected].styleNormal}>Kristina Pevec, kristina.pevec@redea.hr, 040/ 395 572</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Projekt je sufinancirala Europska unija iz Europskog fonda za regionalni razvoj.' : 'The project was co-financed by the European Union from the European Fund for Regional Development.'}</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal} onClick={() => openInNewTab('https://www.strukturnifondovi.hr')}> www.strukturnifondovi.hr</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal} onClick={() => openInNewTab('https://strukturnifondovi.hr/eu-fondovi/esi-fondovi-2014-2020/op-konkurentnost-i-kohezija/')}>www.strukturnifondovi.hr/op-konkurentnost-i-kohezija-2014-2020-779</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <img src={ImageEu} className="img-fluid"  alt="EUfonds" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleSub}>01/2019.</p>
        </div>
      </div>





      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'Certifikacijom proizvoda do tržišta' : 'From product certification to the market'}</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Poduzeće PINKY-S d.o.o. za zastupanje inozemnih tvrtki,uvoz-izvoz i ugostiteljstvo je poduzeće s dugogodišnjom tradicijom, osnovano 1993. g. Posluje u industriji betona te industriji boja i lakova, za koje nudi široku paletu proizvoda i usluga vlastite inovativne proizvodnje. Ideja proizvodnje aditiva javila se još prije desetak godina kada smo opremili vlastiti laboratorij za razvoj i krenuli s proizvodnjom aditva za betonsku galenteriju. U želji da upotpuni ponudu vlastitih proizvoda za betonsku industriju, stručni tim naših kemiskih inžinjera 2017. godine završio je i razvoj aditiva za transportne betone.' : 'The company PINKY-S d.o.o. has a long tradition. It was founded in 1993 and its core activities are in the concrete, paint and coating industry, for which it offers a wide range of products and its own innovative production processes. The idea of producing additives was born a decade ago when we equipped our own development laboratory and started producing additives for concrete accessories. In order to expand our product range in the concrete industry, in 2017, our chemical engineers completed the development of additives for ready-mix concrete/transport concrete.'}</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <img src={ImageEU2} className="img-fluid"  alt="EUfonds2" />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Novo razvijene proizvode prije stavljanja na tržište potrebno je certificirati i uskladiti s normama te dokazati kvalitetu, sigurnost i pouzdanost proizvoda. U tu svrhu poduzeće se prijavilo na natječaj „Certifikacijom proizvoda do tržišta“.' : 'Newly developed products must be certified and harmonized according to relevant standards before being put on the market, and the quality, safety and reliability of the product must be proven. For this purpose, the company submitted an application for a tender "Certification of products for the market".'}</p>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Nakon uspješne prijave projekta „Certificiranjem novih proizvoda poduzeća Pinky-S d.o.o. do povećanja konkurentnosti poduzeća“, poduzeće provodi projekt u razdoblju od 12. srpnja 2017. do 12. srpnja 2018. godine. Provedbom projekta certificirat će se osam vlastito razvijenih proizvoda - novih vrsta aditiva za transportni beton i beton za proizvodnju betonskih elemenata, eliminirajući problem nepostojanja certifikata i nemogućnost plasiranja novih proizvoda na tržište.' : 'After the successful application for the project "Certification of new products of the company Pinky-S d.o.o. to increase the competitiveness of the company", the company will be implementing the project in the period from July 12, 2017 to July 12, 2018. The implementation of the project will certify eight self-developed products - new types of additives for ready-mix concrete /transport concrete and concrete for the production of concrete elements, eliminating the problem of no certificate and the impossibility of placing new products on the market.'}</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <img src={ImageEU3} className="img-fluid"  alt="EUfonds3" />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Cilj projekta je povećanje konkurentnosti na domaćem i inozemnim tržištima putem proširenja proizvodnog asortimana što će rezultirati uvođenjem osam novih certifikata za vlastite proizvode, povećanjem prihoda od prodaje za 11,46 % i povećanjem prihoda od izvoza za 6,13 %. Ukupna vrijednost projekta iznosi 119.584,66 HRK, dok iznos dobivenih bespovratnih sredstva iznosi 92.331,00 HRK.' : 'The goal of the project is to increase competitiveness on the domestic and foreign markets by expanding the product range, which will be facilitated by the eight new certificates for our own products; it will result in an increase in sales revenue by 11.46% and an increase in export revenue by 6.13%. The total value of the project amounts to HRK 119,584.66, while the amount received in grants is HRK 92,331.00.'}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'U pripremi i provedbi projektnih aktivnosti sudjeluju vanjski stručnjaci iz REDEA-e koji posjeduju dugogodišnje iskustvo u pripremi i provođenju projekata, provođenju postupka javne nabave, kao i savjetovanju poduzetnika.' : 'The preparation and implementation of project activities was helped by the experts from the regional development agency REDEA. They have many years of experience in the preparation and implementation of projects, the implementation of public procurement procedures as well as in advising entrepreneurs.'}</p>
        </div>
      </div>


      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Za sve dodatne informacije slobodno nam se obratite:' : 'For any additional information, feel free to contact us:'}</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>Spomenka Koprivec, pinky-s@pinky-s.com, 040/ 360 700</p>
          <p style={options[textSizeSelected].styleNormal}>Kristina Pevec, kristina.pevec@redea.hr, 040/ 395 572</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Projekt je sufinancirala Europska unija iz Europskog fonda za regionalni razvoj.' : 'The project was co-financed by the European Union from the European Fund for Regional Development.'}</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal} onClick={() => openInNewTab('https://www.strukturnifondovi.hr')}> www.strukturnifondovi.hr</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal} onClick={() => openInNewTab('https://strukturnifondovi.hr/eu-fondovi/esi-fondovi-2014-2020/op-konkurentnost-i-kohezija/')}>www.strukturnifondovi.hr/op-konkurentnost-i-kohezija-2014-2020-779</p>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-8 col-sm-12">
          <img src={ImageEu} className="img-fluid"  alt="EUfonds" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleSub}>01/2017.</p>
        </div>
      </div>



    </div>
  );
};

export default EUProjects;
