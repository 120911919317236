import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/plavi_ultramarin.png";
const PigmentsBlue = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)
  return (
    <div className="container">
      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'PLAVI ULTRAMARINE' : 'BLUE ULTRAMARINE'}</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 col-sm-12">
          <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="PigmentBlue" />
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
            {langSelected === 0 ? 'Ultramarine plavi je sintetički pigment plave boje. Kemijski,to je natrijev alumino-sulfosilikat. Taj pigment ima jedinstvenu čistu i živu nijansu plave boje.  Zbog svoje boje veoma je primjenjiv kao izbjeljivač odnosno neutralizator žutih nijansi, te se kao takav široko primjenjuje u industriji boja, plastike, gume, papira, te u kemijskoj industriji.' : 'Blue Ultramarine is a synthetic blue pigment. Its chemical composition is sodium alumino-sulpho silicate. This pigment has a unique, pure and vivid shade of blue. Because of its colour, it is very applicable as a bleach or neutralizer of yellow shades, and as such, is widely used in the paint, plastic, rubber, paper, and chemical industries.'}
            <br/>
            {langSelected === 0 ? 'To je bezopasan pigment čija je sigurnost neupitna. Odobren je po svim standardima, te se kao takav može koristiti u izradi ambalaže za hranu, igračaka, te u proizvodnji kozmetike.' : 'It is a harmless pigment that is unquestionably safe. It is approved by all standards, and as such, it can be used in the production of food packaging, toys, and in the production of cosmetics. '}
            {langSelected === 0 ? 'Glavne značajke:' : 'Main features:'}
            <br/>
            {langSelected === 0 ? '- Temperaturna stabilnost do 350°C' : '- Temperature stability up to 350°C'}
            <br/>
            {langSelected === 0 ? '- Veoma lako dispergiranje' : '- Very easy dispersing'}
            <br/>
            {langSelected === 0 ? '- Odlična postojanost na svjetlo, te otpornost na atmosferilije' : '- Light stability and resistance to atmospheric conditions'}
            <br/>
            {langSelected === 0 ? '- Odličan odnos cijene i performansi' : '- Excellent price-performance ratio'}
            <br/>
            {langSelected === 0 ? '- Ekološki prihvatljiv' : '- Environmentally acceptable'}
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>



    </div>
  );
};

export default PigmentsBlue;
