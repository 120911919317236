import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";

const Carousel = () => {
  const langSelected = useFontSizeStore((state) => state.lang)
  return (
    <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
      <ol className="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
      </ol>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <div className="item slide1">
            <div className="container">
              <div className="carousel-caption">
                <h1>{langSelected === 0 ? 'PIGMENTI' : 'PIGMENTS'}</h1>
                <p>{langSelected === 0 ? 'Izrazite se bojom' : 'Express yourself with colour'}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="carousel-item">
            <div className="item slide2">
              <div className="container">
                <div className="carousel-caption">
                  <h1>{langSelected === 0 ? 'ADITIVI' : 'ADDITIVES'}</h1>
                  <p>{langSelected === 0 ? '„Kap“ koja čini razliku' : 'A “drop” that makes a difference'}</p>
                </div>
              </div>
            </div>
        </div>

        <div className="carousel-item">
            <div className="item slide3">
              <div className="container">
                <div className="carousel-caption">
                  <h1>{langSelected === 0 ? 'OPREMA' : 'EQUIPMENT'}</h1>
                  <p>{langSelected === 0 ? 'Preciznost je „ključ“' : 'Precision is the “key”'}</p>
                </div>
              </div>
            </div>
        </div>

        <div className="carousel-item">
            <div className="item slide4">
              <div className="container">
                <div className="carousel-caption">
                  <h1>{langSelected === 0 ? 'AUTOMATIKA' : 'AUTOMATION'}</h1>
                  <p>{langSelected === 0 ? 'Znanje u službi automatike' : 'Knowledge in the service of automation'}</p>
                </div>
              </div>
            </div>
        </div>
      </div>
      <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
};

export default Carousel;
