import React from 'react';
import ImgDot from '../assets/tockice/roza_tockica.png'
//<img  src={ImgDot} style={{height: '10px', width: '10px'}} alt="Img1"/>

const Dots = (props) => {
  const {numberOf} = props

  let lis = [];
  for (let i=1; i <= numberOf; i++) {
    lis.push(<span key={i}><img  src={ImgDot} style={{height: '10px', width: '10px', marginRight: '3px'}} alt="Img1"/></span>)
  }

  return (
    <>
      {lis}
    </>
  );
}

export default Dots;