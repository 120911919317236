const autoHR = {
  menu1: 'TRANSPORTNI BETON',
  menu2: 'NADZORNO-OTPREMNIČKI SUSTAV BETONARA',
  menu3: 'BETONSKE KONSTRUKCIJE I GALANTERIJA',
  menu4: 'PRIPREMA I DOZIRANJE DISPERZIJE PIGMENATA',
  menu5: 'DOZIRANJE KEMIJSKIH DODATAKA',
  menu6: 'KALIBRACIJA VLAGOMJERA',
  menu7: 'SOFTWARE MOSNA VAGA',
  menu8: 'PUNILICA BOJE',
  menu9: 'PROGRAMSKE ANALIZE',
  menu10: 'REFERENCE AUTOMATIKA',
}

const autoEN = {
  menu1: 'TRANSPORTNI BETON',
  menu2: 'SUPERVISORY AND DISPATCH SYSTEM OF CONCRETE EQUIPMENT',
  menu3: 'CONCRETE STRUCTURES AND RELATED GOODS',
  menu4: 'PREPARATION AND DOSING OF PIGMENT DISPERSION',
  menu5: 'DOSING OF CHEMICAL ADDITIVES',
  menu6: 'CALIBRATION OF CONCRETE MOISTURE METER ',
  menu7: 'SOFTWARE MOSNA VAGA',
  menu8: 'PUNILICA BOJE',
  menu9: 'PROGRAMME ANALYSIS',
  menu10: 'REFERENCES AUTOMATION',
}

export { autoHR, autoEN}
