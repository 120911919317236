import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/zeljezni_oksidi.png";

const PigmentsIronOxide = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
    const langSelected = useFontSizeStore((state) => state.lang)
  return (
    <div className="container">
      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'ŽELJEZNI OKSIDI' : 'PIGMENTS'}</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 col-sm-12">
          <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="Pigmenti1" />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}></p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'U klasificiranju željeznih oksida, postoje bitne razlike između sintetičkih i prirodnih željeznih oksida. Sintetički željezo-oksidni pigmenti rađeni su kontroliranim proizvodnim procesom, uz kontrolirano mljevenje na određenu veličinu čestica, te samim time ostvaruju prednosti u odnosu na pigmente prirodnih željeznih oksida koji su vađeni u prirodnom ležištu rude. Upravo zbog tih razlika, a k tome dodajući primjerenu cijenu, otpornost i izdržljivost, uz „zemljane“ nijanse, sintetički željezo-oksidni pigment prevladava u betonskoj industriji.' : 'In classifying iron oxides, there are important differences between synthetic and natural iron oxides. Synthetic iron-oxide pigments are made using a controlled production process, with controlled grinding to a certain particle size, and thus have an advantage over natural iron oxide pigments extracted from natural ore deposits. Precisely because of these differences, not forgetting the more affordable price, better resistance and durability, as well as their "earthy" shades, synthetic iron-oxide pigments prevail in the concrete industry.'}
            <br/>
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>


      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'U svojoj ponudi, PINKY-S Vam nudi proizvode najvećeg svjetskog proizvođača „Venator Pigments“. Dostupan je cijeli niz crvenih, žutih, narančastih, crnih i smeđih pigmenata. Nudimo ih kao prašak, granule ili suspenziju. Dugogodišnje iskustvo nam ukazuje na njihovu svjetlostabilnost, stabilnost na atmosferilije, te otpornost na kiseline, lužine i visoke temperature.' : 'At Pinky-S we offer products from the world\'s largest manufacturer "Venator Pigments". A full range of red, yellow, orange, black and brown pigments are available. We offer them as powder, granules or suspension. Many years of experience indicate their light and atmospheric stability, resistance to acids, alkalis and high temperatures.'}
            <br/>
              {langSelected === 0 ? 'Veoma su široko primjenjivi, što znači da se mogu koristiti u bilo kojem dijelu građevinske industrije (beton, asfalt), industrije boja i lakova, kemijsko-tehnološke industrije itd.' : 'They are widely applicable, meaning that they can be used in any part of the construction industry (concrete, asphalt), paint and coatings industry, chemical-technological industry, etc'}
            <br/>
              {langSelected === 0 ? 'Nudimo i svoju robnu marku tekućih pigmenata „PINOXID“ .' : 'We also offer our brand of liquid pigments "PINOXID".'}
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>


    </div>
  );
};

export default PigmentsIronOxide;
