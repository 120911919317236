import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";

const Equipment2 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  return (
    <>
      <div className="container">

        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Kako se pri radu se pojavljuju brojni problemi: određivanje potrebne količine i vrste dodataka i pigmenata (praškasti, granule ili tekući), priprema tekućeg pigmenta, miješanje dodataka u beton, kada ih dodati (prije doziranja vode, poslije doziranja vode...), trošenje dijelova strojeva, PINKY-S je počeo proizvoditi specifičnu opremu za rad:' : 'As many problems might arise at work e.g. determining the required amount and a type of additives and pigments (powder, granules or liquid), or while preparing liquid pigments or mixing additives into concrete, or choosing the right timing (before dosing of water, after dosing of water...), or wear and tear of machine parts… thus PINKY-S started to manufacture specific work equipment:'}
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>

        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? '• strojeve za pripremu i doziranje praškastih i granulastih pigmenata' : '• machines for preparation and dosing of powder and granular pigments'} <br/>
              {langSelected === 0 ? '• strojeve za pripremu i doziranje tekućih pigmenata' : '• machines for preparation and dosing of liquid pigments'}<br/>
              {langSelected === 0 ? '• aparate za nanošenje premaza' : '• devices for coating'}<br/>
              {langSelected === 0 ? '• punilice boja' : '• paint filling devices'}<br/>
              {langSelected === 0 ? '• opremu za doziranje kemijskih dodataka (aditiva)' : '• equipment for dosing chemical supplements (additives)'}<br/>
              {langSelected === 0 ? '• rezervne dijelove opreme za proizvodnju betonskog crijepa i drugih betonskih proizvoda' : '• spare parts for equipment used in the production of concrete roof tiles and other concrete products'}<br/>
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>


        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Pri rješavanju Vaših tehnoloških problema PINKY-S koristi vlastite inovacije, čime pomaže poboljšanju kvalitete Vaših gotovih proizvoda. U suradnji sa svojim poslovnim partnerima PINKY-S obavlja i servis betonara.' : 'When solving your technological problems, PINKY-S uses its own innovations, which helps to improve the quality of your finished products. In cooperation with its business partners, PINKY-S also provides the concrete plants servicing'}
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>

      </div>
    </>
  );
};

export default Equipment2;
