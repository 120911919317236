import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from '../assets/doziranje_dodatka_za_beton.png'

const Equipment3 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  return (
    <>

      <div className="container">
        <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="Oprema1" />


        <div className="row">
          <div className="col-12">
            <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'DOZIRANJE DODATAKA ZA BETON' : 'DOSING OF ADDITIVES FOR CONCRETE'} </p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}> {langSelected === 0 ? 'Dodaci u betonskoj industriji se koriste da poboljšaju svojstva betona. Naša oprema Vam omogućuje doziranje do 4 različita aditiva u dvije posude. Kod doziranja treba voditi brigu da ne dođe do njihove međusobne kemijske reakcije te smanjenja njihove efikasnosti ili pak do neefikasnosti. Vođeni našim iskustvom konstruirali smo opremu kao i software koji nude najbolje rješenje za Vas uz minimalni gubitak.' : 'Admixtures in the concrete industry are used to improve the properties of concrete. Our equipment allows to dose up to 4 different additives in two containers. When dosing, care should be taken to prevent chemical reactions between them and a reduction in their effectiveness or inefficiency. Guided by our experience, we have designed equipment and software that offer the best solution for you with minimal loss.'}</p>
          </div>
        </div>


        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? '• vaga za aditive ili vaga aditiva ili vaga za aditiv' : '• dosing scale for additives'}<br/>
              {langSelected === 0 ? '• vaga za boje ili vaga boje' : '• dosing scale for paint'}<br/>
              {langSelected === 0 ? '• vaga za dodatke' : '• dosing scale for supplements'}<br/>
            </p>
          </div>
        </div>


      </div>
    </>
  );
};

export default Equipment3;
