import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/crijep 1_m.jpeg";
import Image2 from "../assets/crijep 2_m.jpeg";
import Image3 from "../assets/crijep 4_m.jpeg";
import Image4 from "../assets/crijep 3_m.jpeg";
import Image5 from "../assets/crijep 5_m.jpeg";
const PigmentsRoofTiles = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <p style={options[textSizeSelected].styleOver}>{langSelected === 0 ? 'BOJA ZA BETONSKI CRIJEP' : 'PAINT FOR CONCRETE  ROOF TILES'}</p>
        </div>
      </div>

      <div className="row m-0 p-0">
        <div className="col-lg-10 col-sm-12 m-0-p-0">
          <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="RoofTiles1" />
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="col-lg-10 col-sm-12 m-0-p-0">
          <img src={Image2} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="RoofTiles1" />
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="col-lg-10 col-sm-12 m-0-p-0">
          <img src={Image3} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="RoofTiles1" />
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="col-lg-10 col-sm-12 m-0-p-0">
          <img src={Image4} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="RoofTiles1" />
        </div>
      </div>
      <div className="row m-0 p-0">
        <div className="col-lg-10 col-sm-12 m-0-p-0">
          <img src={Image5} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="RoofTiles1" />
        </div>
      </div>


      <div className="row mt-1">
        <div className="col-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Kad je na tržištu primijećena potreba za kvalitetnim premazom za betonski crijep, PINKY-S je krenuo i u tom smjeru. Prilikom razvoja naših boja, posebna pažnja posvećena je samoj kvaliteti korištenih sirovina. Važno je spomenuti da se u proizvodnji naših boja za betonski crijep koriste isključivo čisto-akrilatna veziva, te hidrofobna punila i naravno, pigmenti na bazi sintetičkih željeznih oksida. Samim tim, postignuta je izuzetna otpornost boje na utjecaj kiselina i lužina, te apsolutna svjetlostabilnost, što je pri uvjetima upotrebe samoga crijepa od presudne važnosti.' : 'PINKY-S responded when a need for quality coating for concrete tiles was noticed on the market. During the development of our paints, special attention was given to the quality of the raw materials used. It is important to emphasize that, in the production of our paints for concrete roof tiles, only pure acrylate binders, hydrophobic fillers and, of course, pigments based on synthetic iron oxides are used. As a result, we achieve exceptional colour resistance to the impact of acids and alkalis, as well as absolute light stability. This is of crucial importance when using roof tiles.'}
            <br/>
              {langSelected === 0 ? 'Osim kvalitete samog premaza, kod proizvodnje obojenog betonskog crijepa, izuzetno je važna i kakvoća aplikacije samog premaza. Svojim iskustvom i znanjem, PINKY-S je u mogućnosti pomoći i najzahtjevnijem kupcu.' : 'In addition to the quality of coating when producing the coloured concrete roof tiles, the quality of application is extremely important. Relying on its experience and knowledge, PINKY-S is able to answer even the most challenging customer request.'}
            <br/>
              {langSelected === 0 ? 'U našem asortimanu nudimo sljedeće standardne nijanse:' : 'From our product range, we offer the following standard shades:'}
            <br/>
              {langSelected === 0 ? '- Boja za betonski crijep NARANČASTA' : '- Paint for concrete roof tiles ORANGE'}
            <br/>
              {langSelected === 0 ? '- Boja za betonski crijep CRVENA' : '- Paint for concrete roof tiles RED'}
            <br/>
              {langSelected === 0 ? '- Boja za betonski crijep SMEĐA' : '- Paint for concrete roof tiles BROWN'}
            <br/>
              {langSelected === 0 ? '- Boja za betonski crijep BORDO (vinsko crvena)' : '- Paint for concrete roof tiles BORDEAUX (wine red)'}
            <br/>
              {langSelected === 0 ? '- Boja za betonski crijep ANTRACIT' : '- Paint for concrete roof tiles ANTHRACITE'}
          </p>
        </div>
      </div>


    </div>
  );
};

export default PigmentsRoofTiles;
