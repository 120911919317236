import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";

const Automation = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  return (
    <>

      <div className="container">


        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Automatika je u cjelosti projektirana i izrađena u PINKY-S d.o.o. Za brže rješavanje mogućih problema svaka automatizirana betonara povezana je internetom ili VPN mrežom. Svaka automatizirana betonara ima i 24 satnu programsku podršku, a ako treba možemo pomoći i savjetom oko mehaničkih problema vezanih za nju.' : 'The automation system is entirely designed and developed by Pinky-S d.o.o. For faster resolution of possible problems, each automated concrete plant is connected via the internet or a VPN network.Every automated concrete plant has 24-hour software support, and if needed, we can also help with advice on related mechanical problems.'}
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>

        <div className="row mt-3">
          <div className="col-1">

          </div>

          <ul className="list-group list-group-flush col-8">
            <li className="list-group-item">
              <p style={options[textSizeSelected].styleNormal}>
                {langSelected === 0 ? '• program' : '• programme'}
              </p>
            </li>
            <li className="list-group-item">
              <p style={options[textSizeSelected].styleNormal}>
                {langSelected === 0 ? '• mehanika' : '• mechanics'}
              </p>
            </li>
            <li className="list-group-item">
              <p style={options[textSizeSelected].styleNormal}>
                {langSelected === 0 ? '• automatika' : '• automation'}
              </p>
            </li>
          </ul>
        </div>

        <div className="row mt-1">
          <div className="col-lg-10 col-sm-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Pri rješavanju Vaših tehnoloških problema PINKY-S koristi vlastite inovacije, program i opremu čime pomaže poboljšanju kvalitete i/ili brzini izrade Vaših gotovih proizvoda. U suradnji sa svojim poslovnim partnerima PINKY-S obavlja i servis betonara te strojeva i uređaja za rad s pigmentima i kemijskim dodacima.' : 'When solving technological problems, Pinky-S uses its own innovations, programmes and equipment to help to improve quality and speed up the development of the finished product. In cooperation with its business partners, Pinky-S also provide services for concrete plants and machines as well as devices for working with pigments and chemical additives.'}
            </p>
          </div>
          <div className="col-lg-2 col-sm-12">

          </div>
        </div>

      </div>
    </>
  );
};

export default Automation;
