import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/zeljezni_krom_oksid.png";
const PigmentsGreen = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  return (
    <div className="container">
      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'ZELENI KROM III OKSID' : 'GREEN CHROMIUM III OXIDE'}</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 col-sm-12">
          <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="PigmentGreen" />
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleHead}></p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Ubraja se među najstarije anorganske pigmente. Zbog svojeg sastava, odlikuje se velikom tvrdoćom i otpornošću prema abraziji te visokoj temperaturi, inertnošću, postojanošću na svijetlo i prema djelovanju vode, kiselina, alkalija i drugih kemikalija. Ima široki spektar primjene, što znači da se može koristiti praktički u svim granama industrije.' : 'It is one of  the oldest inorganic pigments. Due to its composition, it is distinguished by its high hardness and resistance to abrasion and high temperatures, inertness, resistance to light and to water, acids, alkalis and other chemicals. It has a wide range of applications, meaning that it can be used practically in all areas of the industry.'}
            <br/>
              {langSelected === 0 ? 'U svojoj ponudi nudimo Vam proizvod marke Elementis Chromium, najvećeg svjetskog proizvođača krom oksida.' : 'In our product range, we offer a product from the company Elementis Chromium, the world\'s largest producer of chromium oxide.'}
            <br/>
              {langSelected === 0 ? 'Model koji nudimo naziva se M-100.' : 'The product in our range is M-100.'}
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>

      <div className="row mt-3">
        <div className="col-12">
          <p style={options[textSizeSelected].styleNormalBold}>{langSelected === 0 ? 'Tehnički podaci:' : 'Technical data:'} </p>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? '- Izgled: fini zeleni prah' : '- Appearance: fine green powder'}
            <br/>
              {langSelected === 0 ? '- Topljivost u vodi: netopljiv' : '- Solubility in water: insoluble'}
            <br/>
              {langSelected === 0 ? '- Otpornost na temperaturu: potpuno otporan do 800°C, a tali se pri 2000°C' : '- Temperature resistance: completely resistant up to 800°C, and melts at 2000°C'}
            <br/>
              {langSelected === 0 ? '- Otpornost na kiseline i lužine: odlična, 20% klorovodične kiseline ili 20% otopina natrijevog hidroksida nema nikakvog utjecaja na pigment.' : '- Acid and alkali resistance: excellent, 20% hydrochloric acid or 20% sodium hydroxide solution has no effect on the pigment.'}
            <br/>
              {langSelected === 0 ? '- Otpornost na otapala: potpuno otporan na sva otapala.' : '- Solvent resistance: completely resistant to all solvents.'}
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>


    </div>
  );
};

export default PigmentsGreen;
