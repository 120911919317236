import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from "../assets/kobalt_plavi.png";
const PigmentsCobalt = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)
  return (
    <div className="container">
      <div className="row">
        <div className="col-8">
          <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'KOBALT PLAVI' : 'COBALT BLUE'}</p>
        </div>
        <div className="col-4">

        </div>
      </div>

      <div className="row">
        <div className="col-lg-10 col-sm-12">
          <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '100%'}} alt="PigmentCobalt" />
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-lg-8 col-sm-12">
          <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Zbog neprikladnosti Ultramarine pigmenata u betonskoj industriji, te kod aplikacije pri povećanim temperaturama, također postoji plavi pigment kojim možemo zadovoljiti i te zahtjeve.' : 'Due to the unsuitability of Ultramarine pigments in the concrete industry, and in applications at higher temperatures, we have an alternative blue pigment. '}
            <br/>
              {langSelected === 0 ? 'Kobalt blue je plavi pigment koji se sastoji od aluminijevog i kobaltovog oksida. On je produkt reakcije kalciniranja na visokoj temperaturi, te ga to čini veoma otpornim. Kobalt plavi pigmenti veoma su stabilni na kiseline i alkalije, te na temperaturu (više od 1000°C). Koristi se za iste namjene kao i Ultramarine, ali je za razliku od Ultramarine pigmenta veoma primjenljiv i u betonskoj, staklenoj i keramičkoj industriji. Jedino on omogućuje trajno obojenje „mase“ betona plavom bojom.' : 'Cobalt blue is a blue pigment consisting of aluminium and cobalt oxides. It is a product of a calcination reaction at a high temperature, which makes it very resistant. Cobalt blue pigments are very stable to acids and alkalis, and to high temperatures (more than 1000°C). They are used for the same purpose as Ultramarine, but unlike the latter, they are also very applicable in the concrete, glass and ceramic industries. Cobalt Blue is the only pigment that enables permanent colouring of a large area of concrete with blue colour.'}
            <br/>
              {langSelected === 0 ? 'U svojoj ponudi, nudimo Vam pigment Cobalt Blue 4500 također od Venator proizvođača pigmenata.' : 'At Pinky-S, we offer the pigment Cobalt Blue 4500, from the company Venator, one of the largest pigment manufacturers.'}
          </p>
        </div>
        <div className="col-lg-4 col-sm-12">

        </div>
      </div>



    </div>
  );
};

export default PigmentsCobalt;
