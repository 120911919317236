import React from 'react';
import {useFontSizeStore} from "../stores/useFontSizeStore";
import Image1 from '../assets/mjerenje_vlage.png'
import Image5 from "../assets/pdf.jpeg";
import Pdf from "../assets/IMKO_radarski_vlagomjeri.pdf";

const Equipment6 = () => {

  const options = useFontSizeStore(state => state.defs)
  const textSizeSelected = useFontSizeStore(state => state.textSizeSelected)
  const langSelected = useFontSizeStore((state) => state.lang)

  const Mailto = ({ email, subject = '', body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

    return <a href={`mailto:${email}${params}`}>{children}</a>;
  };

  return (
    <>

      <div className="container">

        <img src={Image1} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="Vlaga5" />

        <div className="row">
          <div className="col-8">
            <p style={options[textSizeSelected].styleHead}>{langSelected === 0 ? 'MJERENJE VLAGE' : 'MOISTURE MEASUREMENT'} </p>
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>{langSelected === 0 ? 'Kod rada u betonskoj industriji potrebno je veliku pozornost posvetiti vlagi agregata kao i gotovog betona. Određivanje vlage direktno utječe na količinu izmiješanog betona, konstantnu konzistenciju i pouzdani V/C faktor. O važnosti vlagomjera i konstantnosti V/C faktora možete pročitati ovdje.' : 'When working in the concrete industry, it is necessary to pay close attention to the moisture of the aggregates as well as the finished concrete. Determination of moisture directly affects the amount of mixed concrete, constant consistency and reliable V/C factor. You can read about the importance of concrete moisture meters/hygrometers and the constancy of the V/C factor here.'}  </p>
          </div>
        </div>

        <img src={Image5} className="ml-auto mr-auto" style={{display: 'block', width: '80%'}} alt="Vlaga5" />

        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Vlagu najčešće mjerimo:' : 'We usually measure humidity:'}<br/>
              {langSelected === 0 ? '• u spremištima materijala' : '• in raw material storage'}<br/>
              {langSelected === 0 ? '• na izlazu (ispustu) materijala' : '• at the exit (discharge) of the material'}<br/>
              {langSelected === 0 ? '• i u miješalici' : '• and in the mixer'}
            </p>
          </div>
        </div>


        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Sonde za mjerenje vlage u građevinarstvu moraju zadovoljiti različite zahtjeve za uporabu u pijesku, šljunku i svježem betonu. Pri usklađivanju s normama u proizvodnji transportnog, samozbijajućeg i drugih vrsta betona vlagomjeri sve više dobivaju na značenju. Stručnjaci za beton često su suočeni s pitanjem, ako i kako ti zahtjevi mogu biti ispunjeni. Sonda za mjerenje vlage temeljena na tehnologiji radara (TDR) zadovoljava te zahtjeve.' : 'Probes for measuring moisture in construction must meet different requirements for use in sand, gravel and fresh concrete. When harmonizing with norms in the production of transport, self-compacting and other types of concrete, moisture meters gain more and more importance. Concrete experts are often faced with a question, if and how these requirements can be met. A moisture measuring probe based on the radar technology (TDR) meets these requirements.'}
            </p>
          </div>
        </div>

        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Vlagomjer ima važnu ulogu u betonskoj industriji. Određivanje vlage u pojedinim agregatima se propisuje zakonski. Norma obvezuje proizvođača betona koji nema vlagomjer da se vlaga agregata određuje svaki dan dok se mjerenje vlage s vlagomjerom mora kontrolirati svakih 7 dana.' : 'The moisture meter plays an important role in the concrete industry. The determination of moisture in individual aggregates is prescribed by law. The standard requires a concrete producer who does not have a moisture meter to determine the aggregate moisture every day, while the moisture measurement with a moisture meter must be checked every 7 days.'}
            </p>
          </div>
        </div>

        <div className="row mt-0">
          <div className="col-12">
            <p style={options[textSizeSelected].styleNormal}>
              {langSelected === 0 ? 'Za detaljne informacije možete pogledati' : 'For detailed information, see the article about the importance of concrete moisture meters / hygrometers '}
              <a href = {Pdf} target = "_blank" rel="noopener noreferrer"> {langSelected === 0 ? 'ovdje' : 'here'} </a>
              {langSelected === 0 ? 'članak o važnosti vlagomjera ili možete kontaktirati' : 'or alternatively you can contact Pinky-s via '}
              <Mailto email="pinky-s@pinky-s.com" subject="Info" body="">
               email: pinky-s@pinky-s.com
              </Mailto>
              {langSelected === 0 ? 'kao i posjetiti proizvođača' : 'and visit the manufacturer'}
              <a href="https://www.imko.de/en/moisture-in-bulk-solids/"> "IMKO" </a>
              {langSelected === 0 ? 'na njegovoj web stranici.' : 'website'}
            </p>
          </div>
        </div>

      </div>
    </>
  );
};

export default Equipment6;
